import { Icon as IconModules, getTheme, pxToRem, styled } from '~/modules';
import Modal from '../Modal';
import Typography from '../Typography';

const textMain = getTheme('text.main');

const spacingMd = getTheme('spacing.md');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(959)};
  max-width: 90%;
  max-height: 95vh;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  justify-content: space-between;
  padding: ${spacingXl}px ${spacingXxl}px;
`;

export const Column = styled.div`
  width: ${pxToRem(350)};
  height: 100%;
  flex-direction: column;
  margin-right: ${spacingMd}px;
`;

export const Icon = styled(IconModules)`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  margin-right: ${spacingMd}px;
  color: ${textMain};
  opacity: 0.3 !important;
`;

export const WrapperItem = styled.div`
  height: max-content;
  margin-bottom: ${spacingXl}px;
  align-items: center;
`;

export const WrapperValue = styled.div`
  flex-direction: column;
`;

export const Label = styled(Typography).attrs({
  variant: 'body2',
})`
  font-weight: bold;
  opacity: 0.5 !important;
`;

export const Value = styled(Typography).attrs({
  variant: 'body1',
})``;
