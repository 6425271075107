import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import { FluidTheme, If, isEmpty } from '~/modules';
import {
  AccountDetails,
  ClientAccounts,
  ClientSearch,
  Clients,
  CompanyRegister,
  Login,
  ProductDetails,
  ProductRegister,
  Products,
  ProductsPermission,
  SideAccountRegister,
  UserActivationError,
  UserDetails,
  Users,
} from '~/scenes';
import { Storage } from '~/services';
import { showAlert, useStores } from '~/utils';
import { Routes } from './routing';

const RoutesContainer: React.FC = () => {
  const {
    user: { isLogged, getUserLogged },
    theme: { theme },
  } = useStores();
  const tokenStorage = Storage.getToken();
  const [token, setToken] = useState<Token>(tokenStorage);
  const [themeVerificated, setThemeVerificated] = useState<FluidTheme>(theme);

  const getUserDetails = async () => {
    try {
      await getUserLogged();
    } catch ({ message }) {
      showAlert({ message });
    }
  };

  useEffect(() => {
    if (token) {
      getUserDetails();
    }
  }, [token]);

  useEffect(() => {
    setToken(tokenStorage);
    setThemeVerificated(theme);
  }, [isLogged, theme]);

  return (
    <If condition={!isEmpty(themeVerificated)}>
      <Switch>
        {isEmpty(token) && (
          <>
            <Route path="*" element={<Navigate to={Routes.LOGIN} />} />
            <Route path={Routes.LOGIN} element={<Login />} />
            <Route
              path={Routes.USER_ACTIVATION_ERROR}
              element={<UserActivationError />}
            />
          </>
        )}
        {!isEmpty(token) && (
          <>
            <Route path="*" element={<Navigate to={Routes.CLIENTS} />} />
            <Route path={Routes.CLIENTS} element={<Clients />} />
            <Route
              path={Routes.COMPANY_REGISTER}
              element={<CompanyRegister />}
            />
            <Route path={Routes.CLIENT_SEARCH} element={<ClientSearch />} />
            <Route path={Routes.CLIENT_ACCOUNTS} element={<ClientAccounts />} />
            <Route
              path={Routes.CLIENT_ACCOUNT_DETAILS}
              element={<AccountDetails />}
            />
            <Route
              path={Routes.SIDE_ACCOUNT_REGISTER}
              element={<SideAccountRegister />}
            />
            <Route path={Routes.USERS} element={<Users />} />
            <Route path={Routes.USER_DETAILS} element={<UserDetails />} />
            <Route
              path={Routes.PRODUCTS_PERMISSION}
              element={<ProductsPermission />}
            />
            <Route path={Routes.PRODUCTS} element={<Products />} />
            <Route
              path={Routes.PRODUCT_REGISTER}
              element={<ProductRegister />}
            />
            <Route path={Routes.PRODUCT_DETAILS} element={<ProductDetails />} />
          </>
        )}
      </Switch>
    </If>
  );
};

export { Routes };
export default observer(RoutesContainer);
