import { FC } from 'react';
import { faCircleUser, faGear, faPlus, faRightFromBracket } from '~/assets';
import { Icon, IconLogout, Item, ItemLogout, Text, Wrapper } from './styles';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onLogout(): Promise<void> | void;
};

const PopUpHeader: FC<Props> = ({ isOpen, onClose, onLogout }) => {
  return (
    <Wrapper isOpen={isOpen} shouldCloseOnOverlayClick onRequestClose={onClose}>
      <Item onClick={() => {}} disabled>
        <Icon icon={faCircleUser} />
        <Text>Meu Perfil</Text>
      </Item>
      <Item disabled>
        <Icon icon={faGear} />
        <Text>Ajustes</Text>
      </Item>
      <Item onClick={() => {}} disabled>
        <Icon icon={faPlus} />
        <Text>Adicionar Usuário</Text>
      </Item>

      <ItemLogout id="logout" onPress={onLogout}>
        <IconLogout icon={faRightFromBracket} />
        <Text>Sair</Text>
      </ItemLogout>
    </Wrapper>
  );
};

export default PopUpHeader;
