import { getTheme, ifStyle, pxToRem, styled } from '~/modules';
import Typography from '../Typography';

const dangerContrast = getTheme('danger.contrast');
const successContrast = getTheme('success.contrast');
const infoContrast = getTheme('info.contrast');
const dangerMain = getTheme('danger.main');
const successMain = getTheme('success.main');
const infoMain = getTheme('info.main');
const backgroundZ2 = getTheme('background.z2');
const textMain = getTheme('text.main');

const themeRadiusButton = getTheme('themeRadius.button');
const spacingMd = getTheme('spacing.md');

const isDanger = ifStyle('danger');
const isSuccess = ifStyle('success');
const isInfo = ifStyle('info');

type Props = {
  danger?: boolean;
  success?: boolean;
  info?: boolean;
};

export const Wrapper = styled.div<Props>`
  background: ${isDanger(
    dangerContrast,
    isSuccess(successContrast, isInfo(infoContrast, backgroundZ2)),
  )};
  height: ${pxToRem(50)};
  min-width: ${pxToRem(70)};
  border-radius: ${themeRadiusButton}px;
  justify-content: center;
  align-items: center;
  padding: 0 ${spacingMd}px;
`;

export const Text = styled(Typography).attrs({ variant: 'body2' })<Props>`
  color: ${isDanger(
    dangerMain,
    isSuccess(successMain, isInfo(infoMain, textMain)),
  )};
  opacity: ${isDanger(1, isSuccess(1, isInfo(1, 0.5)))} !important;
`;
