import styled from 'styled-components';
import { LogoBuildersBankDark } from '~/assets';
import { BackgroundLetters, Button, TextInput, Typography } from '~/components';
import { Icon as IconComponent, getTheme, pxToRem } from '~/modules';
import { mqDevices } from '~/utils';

const brandPrimaryLight = getTheme('brand.primary.light');
const infoContrast = getTheme('info.contrast');
const backgroundZ3 = getTheme('background.z3');
const textLink = getTheme('text.link');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

const themeRadiusCard = getTheme('themeRadius.card');
const borderRadiusMax = getTheme('borderRadius.max');
const primaryGlow = getTheme('boxShadow.primary glow');

export const Wrapper = styled(BackgroundLetters)``;

export const ContentBox = styled.div`
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: ${spacingLg}px;
  z-index: 2;
`;

export const Logo = styled(LogoBuildersBankDark)`
  width: 100%;
  height: ${pxToRem(100)};
  max-width: ${pxToRem(500)};
  margin-bottom: ${spacingXl}px;
`;

export const LogoClient = styled.img`
  width: 100%;
  height: ${pxToRem(100)};
  max-width: ${pxToRem(500)};
  margin-bottom: ${spacingXl}px;
`;

export const Form = styled.div`
  background: ${backgroundZ3};
  border-radius: ${themeRadiusCard}px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
  max-width: ${pxToRem(850)};
  min-width: ${pxToRem(420)};
  flex: 1;

  @media ${mqDevices.inTablet} {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }
`;

export const FormContentBox = styled.div`
  flex-direction: column;
  width: 72%;
  align-items: center;
  max-width: ${pxToRem(600)};
`;

export const Input = styled(TextInput)`
  margin: ${spacingSm}px 0;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  border-radius: ${borderRadiusMax}px;
  margin: ${spacingLg}px 0;
  ${primaryGlow};
`;

export const ForgotPassword = styled(Typography)`
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  color: ${textLink};
  margin-top: ${spacingMd}px;
`;

export const WelcomeBox = styled.div`
  flex-direction: column;
  flex: 1;
  margin-left: ${spacingXxl}px;
`;

export const PrimaryTitle = styled(Typography).attrs({ variant: 'h1' })`
  color: ${brandPrimaryLight};
  font-weight: 900;
`;

export const Description = styled(Typography).attrs({ variant: 'h6' })`
  color: ${infoContrast};
  font-weight: normal;
  margin: ${spacingMd}px 0;
`;

export const Subtitle = styled(Description)`
  font-weight: bold;
`;

export const IconsBox = styled.div`
  flex-direction: row;
  margin-top: ${spacingMd}px;
`;

export const IconLink = styled.a`
  cursor: pointer;
`;

export const Icon = styled(IconComponent)`
  font-size: ${pxToRem(32)};
  color: ${infoContrast};
  margin-right: ${spacingXl}px;
`;
