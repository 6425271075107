import { ClientsApi } from '~/api';
import { action, makeObservable, observable, persist } from '~/modules';
import { AlertMessages, EventOptionsAccount } from '~/utils';

export default class ClientsStore {
  @persist('object')
  @observable
  currentClient: Client = {} as Client;

  @persist('object')
  @observable
  selectedAccount: Account = {} as Account;

  @persist('object')
  @observable
  documentSpotlightSearch?: string;

  constructor() {
    makeObservable(this);
  }

  @action
  setSelectedAccount = (account: Account) => {
    this.selectedAccount = account;
  };

  @action
  getClientAccount = async (document: string): Promise<void> => {
    this.currentClient = await ClientsApi.getClientAccounts(document);
  };

  @action
  setDocumentSpotlightSearch = (document?: string) => {
    this.documentSpotlightSearch = document || undefined;
  };

  @action
  setStatusAccount = async (
    accountId: string,
    optionSelected?: EventOptionsAccount,
  ): Promise<string> => {
    this.selectedAccount = await ClientsApi.setStatusAccount(
      accountId,
      optionSelected,
    );
    await this.getClientAccount(this.currentClient.document || '');

    let message = '';
    switch (optionSelected) {
      case EventOptionsAccount.INACTIVE:
        message = AlertMessages.SUCCESS_INACTIVE_ACCOUNT;
        break;
      case EventOptionsAccount.ACTIVE:
        message = AlertMessages.SUCCESS_ACTIVE_ACCOUNT;
        break;
      case EventOptionsAccount.CANCEL:
        message = AlertMessages.SUCCESS_CANCEL_ACCOUNT;
        break;
      default:
        break;
    }

    return message;
  };

  @action
  sideAccountRegister = async (values: SideAccountRegister): Promise<void> => {
    this.selectedAccount = await ClientsApi.sideAccountRegister(values);

    this.currentClient.accounts?.push(this.selectedAccount);
  };

  @action
  companyRegister = async (values: CompanyRegister): Promise<void> => {
    this.currentClient = await ClientsApi.companyRegister(values);
  };
}
