import { FluidTheme, themeFormatter } from '@platformbuilders/theme-toolkit';
import { action, makeObservable, observable, persist } from '~/modules';
import { Firestore, Storage } from '~/services';
import { animations, theme } from '~/theme';
import { breakpoints, toInt } from '~/utils';

const { REACT_APP_ENV } = process.env;

const themeJson: FluidTheme = {
  ...themeFormatter(theme),
  ...animations,
};
const subdomain = window.location.hostname.split('.')[0];
export default class ThemeStore {
  @persist('object')
  @observable
  darkTheme: FluidTheme = themeJson;

  @persist('object')
  @observable
  lightTheme: FluidTheme = themeJson;

  @persist('object')
  @observable
  theme: FluidTheme = {} as FluidTheme;

  @persist('object')
  @observable
  clientProvider?: ClientProvider;

  @observable
  isTablet = false;

  @observable
  isDesktop = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setDarkTheme = (): void => {
    this.theme = this.darkTheme;
  };

  @action
  setLightTheme = (): void => {
    this.theme = this.lightTheme;
  };

  @action
  setThemes = async (): Promise<void> => {
    const lightTheme = this.clientProvider?.lightTheme;
    const darkTheme = this.clientProvider?.darkTheme;

    if (lightTheme) {
      const themeFromJson: FluidTheme = {
        ...themeFormatter(JSON.parse(lightTheme)),
        ...animations,
      };

      this.theme = themeFromJson;
      this.lightTheme = themeFromJson;
    }
    if (darkTheme) {
      const themeDarkFromJson: FluidTheme = {
        ...themeFormatter(JSON.parse(darkTheme)),
        ...animations,
      };

      this.darkTheme = themeDarkFromJson;
    }
  };

  @action
  fetchClientProvider = async (): Promise<void> => {
    this.clientProvider = await Firestore.getDocData(
      REACT_APP_ENV || '',
      subdomain,
    );
    this.setThemes();
    Storage.setClientId(this.clientProvider?.clientId || '');
  };

  @action
  setDevice = (currentWidth: number): void => {
    this.isTablet = currentWidth < toInt(breakpoints.desktop);
    this.isDesktop = currentWidth >= toInt(breakpoints.desktop);
  };
}
