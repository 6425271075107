import { Icon as IconModules, getTheme, pxToRem, styled } from '~/modules';
import Button from '../Button';
import Modal from '../Modal';
import Typography from '../Typography';

const textMain = getTheme('text.main');

const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');

export const Wrapper = styled(Modal)`
  width: ${pxToRem(500)};
  max-width: 90%;
  flex-direction: column;
  padding: ${spacingXl}px;
`;

export const Icon = styled(IconModules)`
  font-size: ${pxToRem(64)};
  color: ${textMain};
`;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  font-weight: bold;
  opacity: 0.8 !important;
  text-align: center;
  margin: ${spacingLg}px 0;
`;

export const Description = styled(Typography)`
  opacity: 0.8 !important;
  text-align: center;
  margin-bottom: ${spacingLg}px;
`;

export const ButtonOk = styled(Button)`
  width: ${pxToRem(250)};
  height: ${pxToRem(51)};
`;
