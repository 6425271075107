import { FC } from 'react';
import { faPlus, faPowerOff } from '~/assets';
import {
  AccountRow,
  OptionButton,
  OptionsList,
  PaginatedList,
  StatusLabel,
} from '~/components';
import {
  AlertMessages,
  ProductStatus,
  ProductTypePt,
  controlAccountsColumns,
  parseEnumToPt,
} from '~/utils';
import {
  ButtonComponent,
  Card,
  Column,
  ColumnData,
  Content,
  Description,
  IconComponent,
  Label,
  LittleCard,
  Name,
  Title,
  Total,
  Wrapper,
  WrapperCard,
  WrapperData,
} from './styles';

type Props = {
  product: Product;
  accounts: ListResponse<Account>;
  isAccountLoading: boolean;
  onChangePage(page: number): void;
  onPressOption(option: ProductStatus, text: AlertMessages): void;
  onPressSeeAll(): void;
};

const ProductDetails: FC<Props> = ({
  product,
  accounts,
  isAccountLoading,
  onChangePage,
  onPressOption,
  onPressSeeAll,
}) => {
  const isActive = product.status === ProductStatus.ACTIVE;
  const { ACTIVE, INACTIVE } = ProductStatus;
  const { INACTIVE_PRODUCT, ACTIVE_PRODUCT } = AlertMessages;

  return (
    <Wrapper titlePage={product.name}>
      <Content>
        <ColumnData>
          <Card>
            <Name>{product.name}</Name>
            <Label>DESCRIÇÃO</Label>
            <Description>{product.description}</Description>
            <WrapperCard>
              <LittleCard>
                <Label>STATUS</Label>
                <StatusLabel status={product.status} />
              </LittleCard>
              <LittleCard>
                <Label>TIPO</Label>
                {parseEnumToPt(product.type, ProductTypePt)}
              </LittleCard>
            </WrapperCard>
          </Card>
          <Card>
            <WrapperData>
              <Label>DADOS</Label>
              <WrapperCard>
                <LittleCard>
                  <Label>METADATA</Label>
                  {product.metadata?.length && product.metadata?.length > 1
                    ? `${product.metadata?.length} campos`
                    : `${product.metadata?.length} campo`}
                </LittleCard>
                <LittleCard>
                  <Label>MCC</Label>
                  {product.merchantCategoryCodes?.length || 0} campos
                </LittleCard>
              </WrapperCard>
            </WrapperData>
            <ButtonComponent id="btn_see_all" onPress={onPressSeeAll}>
              <IconComponent icon={faPlus} />
              Ver tudo
            </ButtonComponent>
          </Card>
        </ColumnData>
        <Column>
          <OptionsList>
            <OptionButton
              id="btn_inactive_product"
              label={isActive ? 'Inativar Produto' : 'Ativar Produto'}
              icon={faPowerOff}
              onPress={() =>
                onPressOption(
                  isActive ? INACTIVE : ACTIVE,
                  isActive ? INACTIVE_PRODUCT : ACTIVE_PRODUCT,
                )
              }
            />
          </OptionsList>
          <Title>
            Contas de controle
            <Total>
              {accounts.paging?.totalElements &&
                `${accounts.paging?.totalElements} no total`}{' '}
            </Total>
          </Title>
          <PaginatedList
            columns={controlAccountsColumns}
            list={accounts.content}
            isLoading={isAccountLoading}
            totalPages={accounts.paging?.totalPages}
            currentPage={accounts.paging?.page}
            onChangePage={onChangePage}
            renderList={(account) => (
              <AccountRow
                offIcon
                balance={account?.balance}
                name={account?.person?.fullName}
                status={account?.status}
                type={account?.type}
              />
            )}
            titleEmptyData="Nenhuma conta de controle vinculada"
            descriptionEmptyData="Não existe conta de controle vinculada a este produto."
          />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default ProductDetails;
