import { FC, useMemo } from 'react';
import { faArrowDown, faArrowUp, faHorizontalRule } from '~/assets';
import { TransactionType, TransactionTypePt, parseEnumToPt } from '~/utils';
import { IconStyled, Text, Wrapper } from './styles';

type Props = {
  type?: TransactionType;
};

const TransactionTypeIndicator: FC<Props> = ({ type }) => {
  const { danger, info, success, icon } = useMemo(() => {
    const success = type === TransactionType.CASH_IN;
    const danger = type === TransactionType.CASH_OUT;
    const info = type === TransactionType.REFUND;
    const icon = success ? faArrowDown : danger ? faArrowUp : faHorizontalRule;

    return { info, success, danger, icon };
  }, [type]);

  return (
    <Wrapper danger={danger} success={success} info={info}>
      <IconStyled icon={icon} danger={danger} success={success} info={info} />
      <Text danger={danger} success={success} info={info}>
        {parseEnumToPt(type, TransactionTypePt)}
      </Text>
    </Wrapper>
  );
};

export default TransactionTypeIndicator;
