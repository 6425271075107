import { FC, ReactNode, useMemo } from 'react';
import { ProductStatusPt, ProductTypePt, UserStatus } from '~/utils';
import { Text, Wrapper } from './styles';

type Props = {
  status?: UserStatus | ProductStatusPt | ProductTypePt;
  children?: ReactNode;
};

const StatusIndicator: FC<Props> = ({ status, children }) => {
  const { danger, info, success } = useMemo(() => {
    let info = false;
    let success = false;
    let danger = false;
    switch (status) {
      case ProductStatusPt.CANCELED:
      case ProductStatusPt.INACTIVE:
      case UserStatus.CANCELLED:
      case UserStatus.BLOCKED:
        danger = true;
        break;
      case ProductStatusPt.ACTIVE:
      case UserStatus.ACTIVE:
        success = true;
        break;
      case ProductTypePt.BENEFITS:
      case ProductTypePt.BANKING:
      case ProductTypePt.CREDIT:
      case UserStatus.PENDING:
        info = true;
        break;
      default:
        break;
    }
    return { info, success, danger };
  }, [status]);

  return (
    <Wrapper danger={danger} success={success} info={info}>
      <Text danger={danger} success={success} info={info}>
        {status || children}
      </Text>
    </Wrapper>
  );
};

export default StatusIndicator;
