import { FC } from 'react';
import { faEnvelope, faLayerPlus, faLocationPin, faMobile } from '~/assets';
import { If, observer } from '~/modules';
import { useStores } from '~/utils';
import PersonInfo from '../PersonInfo';
import {
  ButtonSeeData,
  Column,
  Contact,
  ContactCard,
  ContactItemBox,
  IconPlus,
  IconStyled,
  Label,
  LabelBox,
  Value,
  Wrapper,
} from './styles';

type Props = {
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
  onPressSeeData(): void;
};

const ClientCardInfo: FC<Props> = ({
  name,
  address,
  email,
  phone,
  onPressSeeData,
}) => {
  const {
    theme: { isTablet },
  } = useStores();

  return (
    <Wrapper>
      <PersonInfo name={name} />
      <If condition={!isTablet}>
        <Contact>Contato</Contact>
      </If>
      <Column>
        <ContactCard>
          <ContactItemBox>
            <IconStyled icon={faEnvelope} />
            <LabelBox>
              <Label>EMAIL</Label>
              <Value>{email || '-'}</Value>
            </LabelBox>
          </ContactItemBox>
          <ContactItemBox>
            <IconStyled icon={faMobile} />
            <LabelBox>
              <Label>TELEFONE</Label>
              <Value>{phone || '-'}</Value>
            </LabelBox>
          </ContactItemBox>
          <ContactItemBox>
            <IconStyled icon={faLocationPin} />
            <LabelBox>
              <Label>ENDEREÇO</Label>
              <Value>{address || '-'}</Value>
            </LabelBox>
          </ContactItemBox>
        </ContactCard>
        <ButtonSeeData id="btn_see_data" onPress={onPressSeeData}>
          <IconPlus icon={faLayerPlus} />
          Ver dados
        </ButtonSeeData>
      </Column>
    </Wrapper>
  );
};

export default observer(ClientCardInfo);
