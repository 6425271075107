import React from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { faFacebook, faInstagram, faLinkedin, faYoutube } from '~/assets';
import { If } from '~/modules';
import { BuildersBankSocialMedia } from '~/utils';
import {
  ContentBox,
  Description,
  Form,
  FormContentBox,
  Icon,
  IconLink,
  IconsBox,
  Input,
  Logo,
  LogoClient,
  PrimaryTitle,
  SubmitButton,
  Subtitle,
  WelcomeBox,
  Wrapper,
} from './styles';

type Props = {
  logoClient?: string;
  isTablet: boolean;
};
const Login: React.FC<Props> = ({ logoClient, isTablet }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    submitForm,
  }: FormikProps<AuthUser> = useFormikContext();

  return (
    <Wrapper>
      <ContentBox>
        <Form>
          <FormContentBox>
            <If condition={isEmpty(logoClient)}>
              <Logo />
            </If>
            <If condition={!isEmpty(logoClient)}>
              <LogoClient src={logoClient} />
            </If>
            <Input
              fullWidth
              label="Nome do usuário"
              placeholder="Digite o nome do usuário aqui"
              id="username"
              name="username"
              type="username"
              error={touched?.username && errors?.username}
              value={values.username}
              onChange={handleChange}
            />
            <Input
              fullWidth
              label="Senha"
              placeholder="Digite a senha aqui"
              id="password"
              name="password"
              type="password"
              error={touched?.password && errors?.password}
              value={values.password}
              onChange={handleChange}
            />
            <SubmitButton id="button_login" onPress={submitForm} onKeyEnter>
              Entrar
            </SubmitButton>
            {/* @TODO -  Add forgot password function when possible */}
            {/* <ForgotPassword>Esqueceu sua senha?</ForgotPassword> */}
          </FormContentBox>
        </Form>
        <If condition={!isTablet}>
          <WelcomeBox>
            <PrimaryTitle>Seja</PrimaryTitle>
            <PrimaryTitle>Bem-vindo!</PrimaryTitle>
            <Description>
              Com todo nosso know-how de mercado conseguimos unir tecnologia de
              ponta com toda a infraestrutura da Builders Bank e parceiros, para
              idealizar soluções como a plataforma do Gringotts. O Gringotts
              possui APIs construídas para atender suas necessidades e permite o
              gerenciamento de contas e benefícios flexíveis nos mínimos
              detalhes.
            </Description>
            <Subtitle>Faça parte da nossa comunidade:</Subtitle>
            <IconsBox>
              <IconLink href={BuildersBankSocialMedia.YOUTUBE} target="_blank">
                <Icon icon={faYoutube} />
              </IconLink>
              <IconLink href={BuildersBankSocialMedia.FACEBOOK} target="_blank">
                <Icon icon={faFacebook} />
              </IconLink>
              <IconLink
                href={BuildersBankSocialMedia.INSTAGRAM}
                target="_blank"
              >
                <Icon icon={faInstagram} />
              </IconLink>
              <IconLink href={BuildersBankSocialMedia.LINKEDIN} target="_blank">
                <Icon icon={faLinkedin} />
              </IconLink>
            </IconsBox>
          </WelcomeBox>
        </If>
      </ContentBox>
    </Wrapper>
  );
};

export default Login;
