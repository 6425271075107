import { getTheme, pxToRem, styled } from '~/modules';
import Button from '../Button';
import Modal from '../Modal';
import TextInput from '../TextInput';
import Typography from '../Typography';

const borderRadiusMax = getTheme('borderRadius.max');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');
const spacingMax = getTheme('spacing.max');

const boxShadowZ4 = getTheme('boxShadow.z4');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(700)};
  max-width: 90%;
  padding: 0 ${spacingMax}px;
  ${boxShadowZ4};
`;

export const ContentBox = styled.div`
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  max-height: 95vh;
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  font-weight: 700;
  margin-top: ${spacingXxl}px;
  margin-bottom: ${spacingLg}px;
  text-align: center;
`;

export const Subtitle = styled(Typography)`
  font-weight: bold;
  margin: ${spacingSm}px 0;
`;

export const RequirementsBox = styled.div`
  flex-direction: column;
  margin-left: 4%;
  margin-bottom: ${spacingMd}px;
`;

export const Requirements = styled(Typography).attrs({ variant: 'body1' })`
  display: list-item;
`;

export const ContentForm = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const Input = styled(TextInput)`
  margin-bottom: ${spacingMd}px;
`;

export const ButtonCreatePassword = styled(Button)`
  width: ${pxToRem(280)};
  height: ${pxToRem(60)};
  margin-top: ${spacingXl}px;
  margin-bottom: ${spacingXxl}px;
  border-radius: ${borderRadiusMax}px;
  align-self: flex-end;
`;
