import { Button, SceneWrapper, Typography } from '~/components';
import { Icon, getTheme, ifStyle, pxToRem, styled } from '~/modules';

const backgroundZ4 = getTheme('background.z4');
const boxShadowZ3 = getTheme('boxShadow.z3');
const dangerContrast = getTheme('danger.contrast');
const successContrast = getTheme('success.contrast');
const infoContrast = getTheme('info.contrast');
const dangerMain = getTheme('danger.main');
const successMain = getTheme('success.main');
const infoMain = getTheme('info.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const borderRadiusMax = getTheme('borderRadius.max');
const themeRadiusCard = getTheme('themeRadius.card');
const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

const isDanger = ifStyle('danger');
const isInfo = ifStyle('info');
const isSuccess = ifStyle('success');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const ContentBox = styled.div`
  flex: 1;
  flex-direction: column;
  padding: ${spacingXxl}px 0;
`;

export const Title = styled(Typography).attrs({ variant: 'h5' })`
  font-weight: bold;
  margin-bottom: ${spacingXl}px;
`;

export const TopBox = styled.div`
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${spacingXl}px;
`;

export const CardsBox = styled.div``;

export const Card = styled.div`
  flex-direction: column;
  height: fit-content;
  max-width: 50%;
  padding: ${spacingMd}px;
  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;
  margin-right: ${spacingMd}px;
  ${boxShadowZ3};
`;

export const CardLabel = styled(Typography).attrs({ variant: 'body2' })``;

export const CardValue = styled(Typography).attrs({ variant: 'h6' })`
  font-weight: bold;
  overflow: scroll;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

type StatusCardProps = {
  danger?: boolean;
  info?: boolean;
  success?: boolean;
};

export const StatusCard = styled(Card)<StatusCardProps>`
  background: ${isDanger(
    dangerContrast,
    isSuccess(successContrast, isInfo(infoContrast)),
  )};
`;

export const StatusCardLabel = styled(CardLabel)<StatusCardProps>`
  color: ${isDanger(dangerMain, isSuccess(successMain, isInfo(infoMain)))};
`;

export const StatusCardValue = styled(CardValue)<StatusCardProps>`
  color: ${isDanger(dangerMain, isSuccess(successMain, isInfo(infoMain)))};
`;

export const ButtonInfo = styled(Button)`
  min-width: ${pxToRem(270)};
  height: ${pxToRem(60)};
  padding: 0 ${spacingSm}px;
  border-radius: ${borderRadiusMax}px;
  margin-top: ${spacingMd}px;
`;

export const IconPlus = styled(Icon)`
  font-size: ${pxToRem(17)};
  color: ${brandPrimaryContrast};
  margin-right: ${spacingMd}px;
`;

export const WrapperOptions = styled.div`
  margin-bottom: ${spacingXl}px;
`;

export const OptionButton = styled(Button)`
  width: ${pxToRem(280)};
  height: ${pxToRem(60)};
  border-radius: ${borderRadiusMax}px;
  margin-right: ${spacingSm}px;
`;

export const IconOption = styled(Icon).attrs({
  fontSize: pxToRem(20),
})`
  margin-right: ${spacingMd}px;
`;
