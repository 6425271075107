import styled from 'styled-components';
import { Icon, getTheme, ifStyle, pxToRem } from '~/modules';
import Typography from '../Typography';

const textMain = getTheme('text.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const brandPrimaryLight = getTheme('brand.primary.light');
const brandPrimaryMain = getTheme('brand.primary.main');
const backgroundZ4 = getTheme('background.z4');
const themeRadiusCard = getTheme('themeRadius.card');
const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const borderRadiusXl = getTheme('borderRadius.xl');

const isFourItens = ifStyle('fourItens');

type Props = {
  fourItens: boolean;
};
export const Row = styled.div`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const Card = styled.div<Props>`
  display: grid;
  grid-template-columns: ${isFourItens(
    '3.5fr 2.1fr 2.9fr 1.1fr',
    '3fr 2fr 2fr 2fr 1fr',
  )};
  align-items: center;
  width: 100%;
  min-height: ${pxToRem(100)};
  padding: 0 ${spacingLg}px;

  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;

  & + & {
    margin-top: ${spacingSm}px;
  }
`;

export const IconCard = styled(Icon)`
  font-weight: 900;
  font-size: ${pxToRem(16)};
  color: ${textMain};
  margin-right: ${spacingMd}px;
`;

export const Name = styled(Typography).attrs({ variant: 'body1' })`
  font-weight: bold;
  flex: 1;
  margin-right: ${spacingMd}px;
  line-height: 120%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const AccountTypeText = styled(Typography).attrs({ variant: 'body1' })`
  margin: 0 ${spacingMd}px;
`;

export const Balance = styled(Typography).attrs({ variant: 'body1' })`
  font-weight: bold;
`;

export const SearchIconBox = styled.div`
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadiusXl}px;
  background: ${(props) =>
    `linear-gradient(180deg, ${brandPrimaryLight(props)} 0%, ${brandPrimaryMain(
      props,
    )} 100%)`};
  cursor: pointer;
`;

export const IconSearch = styled(Icon)`
  font-size: ${pxToRem(24)};
  color: ${brandPrimaryContrast};
  font-weight: 900;
`;
