import { FC } from 'react';
import { IconDefinition } from '~/assets';
import { IconOption, OptionButton } from './styles';

type Props = {
  id: string;
  icon: IconDefinition;
  label: string;
  disabled?: boolean;
  onPress(): void;
};

const OptionsList: FC<Props> = ({
  id,
  icon,
  label,
  disabled = false,
  onPress,
}) => (
  <OptionButton id={id} tertiary onPress={onPress} disabled={disabled}>
    <IconOption icon={icon} />
    {label}
  </OptionButton>
);

export default OptionsList;
