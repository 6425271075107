import { FC } from 'react';
import { faReceipt } from '~/assets';
import { currencyParser } from '~/modules';
import { TransactionType, formatTimestampToDateOrToday } from '~/utils';
import TransactionTypeIndicator from '../TransactionTypeIndicator';
import { ButtonReceipt, Card, Description, IconReceipt, Row } from './styles';

type Props = {
  transactionType?: TransactionType;
  value?: number;
  date?: number;
  onPress(): void;
};

const AccountTransactionRow: FC<Props> = ({
  date,
  transactionType,
  value,
  onPress,
}) => {
  return (
    <Card>
      <Row>
        <TransactionTypeIndicator type={transactionType} />
      </Row>
      <Row>
        <Description>{currencyParser(value || '')}</Description>
      </Row>
      <Row>
        <Description>{formatTimestampToDateOrToday(date)}</Description>
      </Row>
      <Row>
        <ButtonReceipt id="btn_receipt" onPress={onPress}>
          <IconReceipt icon={faReceipt} />
        </ButtonReceipt>
      </Row>
    </Card>
  );
};

export default AccountTransactionRow;
