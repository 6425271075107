import { FC } from 'react';
import { faChartNetwork, faCircleUser, faUsers, faXmark } from '~/assets';
import { If, isEmpty, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { getActivatedRoute } from '~/utils';
import ItemMenu from './ItemMenu';
import {
  ButtonClose,
  Content,
  Heading,
  IconClose,
  Icons,
  Logo,
  LogoClient,
  Wrapper,
} from './styles';

type Props = {
  onFocus: boolean;
  logoClient?: string;
  isTablet: boolean;
  onClose(): void;
};

const SideMenu: FC<Props> = ({ onFocus, logoClient, isTablet, onClose }) => {
  const navigate = useNavigate();

  const handleNavigate = (route: Routes) => {
    navigate(route);
  };

  return (
    <Wrapper focus={onFocus}>
      <Content>
        <Heading>
          <If condition={isEmpty(logoClient)}>
            <Logo />
          </If>
          <If condition={!isEmpty(logoClient)}>
            <LogoClient src={logoClient} />
          </If>
          <If condition={isTablet}>
            <ButtonClose id="close_side_menu" onPress={onClose}>
              <IconClose icon={faXmark} />
            </ButtonClose>
          </If>
        </Heading>
        <ItemMenu
          id="item_menu_clients"
          text="Clientes"
          icon={<Icons icon={faCircleUser} />}
          activatedRoute={getActivatedRoute(Routes.CLIENTS)}
          onNavigate={() => handleNavigate(Routes.CLIENTS)}
        />
        <ItemMenu
          id="item_menu_products"
          text="Produtos"
          icon={<Icons icon={faChartNetwork} />}
          activatedRoute={getActivatedRoute(Routes.PRODUCTS)}
          onNavigate={() => handleNavigate(Routes.PRODUCTS)}
        />
        <ItemMenu
          id="item_menu_users"
          text="Usuários"
          icon={<Icons icon={faUsers} />}
          activatedRoute={getActivatedRoute(Routes.USERS)}
          onNavigate={() => handleNavigate(Routes.USERS)}
        />
      </Content>
      {/* @TODO -  add functionality to settings when possible */}
      {/* <Footer>
        <ItemMenu
          activatedRoute={getActivatedRoute(Routes.SETTINGS)}
          icon={<Icons icon={faGear} />}
          text="Settings"
          onNavigate={() => handleNavigate(Routes.SETTINGS)}
        />
      </Footer> */}
    </Wrapper>
  );
};

export default SideMenu;
