import { FC } from 'react';
import {
  faCalendar,
  faEnvelope,
  faFileContract,
  faGlobe,
  faIdBadge,
  faIdCard,
  faLocationPin,
  faLocationPlus,
  faMobile,
  faPersonDress,
  faUser,
  faVenusMars,
} from '~/assets';
import { If } from '~/modules';
import {
  GenderPt,
  PersonType,
  formatAddress,
  formatPhone,
  formatShortDateFromString,
  getActiveAddress,
  maskCpfOrCnpj,
  parseEnumToPt,
} from '~/utils';
import HeaderModal from '../HeaderModal';
import Item from './Item';
import { Column, Content, Wrapper } from './styles';

type Props = {
  dataClient: Client;
  isOpen: boolean;
  handleClose(): void;
};

const ModalClientDetails: FC<Props> = ({ dataClient, isOpen, handleClose }) => {
  const { mail, phone, addresses, document, individual, company } = dataClient;
  const activeAddress = getActiveAddress(addresses);

  return (
    <Wrapper isOpen={isOpen} onClose={handleClose}>
      <HeaderModal title="Dados do cliente" onPressClose={handleClose} />
      <Content>
        <Column>
          <Item label="TELEFONE" icon={faMobile} value={formatPhone(phone)} />
          <Item label="EMAIL" icon={faEnvelope} value={mail} />
          <Item
            label="ENDEREÇO"
            icon={faLocationPin}
            value={formatAddress(activeAddress)}
          />
          <Item
            label="COMPLEMENTO DO ENDEREÇO"
            icon={faLocationPlus}
            value={activeAddress?.complement}
          />
          <If condition={dataClient?.personType === PersonType.PJ}>
            <Item icon={faGlobe} label="WEBSITE" value={company?.website} />
          </If>
        </Column>

        <Column>
          <If condition={dataClient?.personType === PersonType.PF}>
            <Item
              label="DOCUMENTO"
              icon={faIdCard}
              value={maskCpfOrCnpj(document)}
            />
            <Item
              label="NOME DA MÃE"
              icon={faPersonDress}
              value={individual?.motherName}
            />
            <Item
              label="GÊNERO"
              icon={faVenusMars}
              value={parseEnumToPt(individual?.gender, GenderPt)}
            />
            <Item
              label="DATA DE NASCIMENTO"
              icon={faCalendar}
              value={formatShortDateFromString(individual?.birthDate)}
            />
          </If>
          <If condition={dataClient?.personType === PersonType.PJ}>
            <Item
              label="CNPJ"
              icon={faIdCard}
              value={maskCpfOrCnpj(document)}
            />
            <Item
              label="RAZÃO SOCIAL"
              icon={faUser}
              value={company?.legalName}
            />
            <Item
              label="NOME FANTASIA"
              icon={faIdBadge}
              value={company?.tradingName}
            />
            <Item
              label="TIPO DA EMPRESA"
              icon={faFileContract}
              value={company?.businessType?.toUpperCase()}
            />
            <Item
              label="INSCRIÇÃO ESTADUAL"
              icon={faFileContract}
              value={company?.stateRegistration}
            />
            <Item
              icon={faIdBadge}
              label="ATIVIDADE PRINCIPAL"
              value={company?.mainActivity}
            />
            <Item
              icon={faCalendar}
              label="DATA DE FUNDAÇÃO"
              value={formatShortDateFromString(company?.foundingDate ?? '')}
            />
          </If>
        </Column>
      </Content>
    </Wrapper>
  );
};

export default ModalClientDetails;
