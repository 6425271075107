export enum TransactionType {
  CASH_IN = 'CASH_IN',
  CASH_OUT = 'CASH_OUT',
  REFUND = 'REFUND',
}

export enum TransactionTypePt {
  CASH_IN = 'Crédito',
  CASH_OUT = 'Débito',
  REFUND = 'Estorno',
}
