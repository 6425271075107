import styled from 'styled-components';
import { Button, SelectInputSearch, Typography } from '~/components';
import { getTheme, pxToRem } from '~/modules';

const spacingMd = getTheme('spacing.md');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

const backgroundZ4 = getTheme('background.z4');

export const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

export const Description = styled(Typography).attrs({
  variant: 'body1',
})`
  margin-top: ${spacingMd}px;
`;

export const SelectInputSearchComponent = styled(SelectInputSearch)`
  margin-top: ${spacingXl}px;
  width: ${pxToRem(450)};
`;

export const NameList = styled(Typography).attrs({ variant: 'h5' })`
  font-weight: bold;
  margin-bottom: ${spacingMd}px;
  margin-top: ${spacingXxl}px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: scroll;
  padding: ${spacingXxl}px;
  padding-bottom: 10%;
`;

export const Footer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: ${pxToRem(100)};
  background-color: ${backgroundZ4};
  gap: ${spacingMd}px;
`;

export const ButtonComponent = styled(Button)`
  width: ${pxToRem(250)};
  height: ${pxToRem(51)};
`;
