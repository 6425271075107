import { FC } from 'react';
import {
  FormikProps,
  If,
  isEmpty,
  isValidCpfCnpj,
  useFormikContext,
} from '~/modules';
import { Routes } from '~/routes';
import { maskCpfOrCnpj } from '~/utils';
import {
  ButtonSearch,
  ContentBox,
  Description,
  Error,
  Input,
  LinkError,
  Title,
  Wrapper,
  WrapperTextError,
} from './styles';

type Props = {
  isSubmitting: boolean;
  error: string;
  onClearErrorPJ(): void;
  onNavigate(route: Routes): void;
};

const ClientSearch: FC<Props> = ({
  isSubmitting,
  error,
  onClearErrorPJ,
  onNavigate,
}) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    submitForm,
  }: FormikProps<SearchClient> = useFormikContext();

  return (
    <Wrapper titlePage="Buscar Cliente">
      <ContentBox>
        <Title>Buscar Cliente</Title>
        <Description>
          A plataforma conta com 2 tipos de pessoas, Pessoa Física e Pessoa
          Jurídica. Para buscar, digite o CPF ou CNPJ completo e clique na opção
          &ldquo;Buscar&ldquo;.
        </Description>
        <Input
          id="document"
          name="document"
          placeholder="Digite o CPF ou CNPJ completo"
          type="filter"
          value={values.document}
          error={touched?.document && errors?.document}
          onChange={({ target }) => {
            setFieldValue('document', maskCpfOrCnpj(target.value));
            onClearErrorPJ();
          }}
        />
        <If condition={!isEmpty(error)}>
          <WrapperTextError onClick={() => onNavigate(Routes.COMPANY_REGISTER)}>
            <Error>
              {`${error}, Se deseja criar uma nova empresa `}
              <LinkError>clique aqui.</LinkError>
            </Error>
          </WrapperTextError>
        </If>

        <ButtonSearch
          id="btn_search_client"
          onLoading={isSubmitting}
          onKeyEnter
          onPress={submitForm}
          disabled={!isValidCpfCnpj(values.document)}
        >
          Buscar
        </ButtonSearch>
      </ContentBox>
    </Wrapper>
  );
};

export default ClientSearch;
