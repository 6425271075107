import { getTheme, styled } from '~/modules';
import Typography from '../Typography';

const spacingMd = getTheme('spacing.md');

export const Wrapper = styled.div`
  align-items: center;
`;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  font-weight: bold;
`;

export const Total = styled(Typography).attrs({ variant: 'h6' })`
  opacity: 0.5 !important;
  margin-left: ${spacingMd}px;
`;
