import { Icon, getTheme, ifStyle, pxToRem, styled } from '~/modules';
import EmptyData from '../EmptyData';
import Typography from '../Typography';

const brandPrimaryLight = getTheme('brand.primary.light');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const textMain = getTheme('text.main');
const themeRadiusButton = getTheme('themeRadius.button');

const spacingXxl = getTheme('spacing.xxl');
const spacingLg = getTheme('spacing.lg');
const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingXs = getTheme('spacing.xs');

const isCurrentPage = ifStyle('currentPage');
const isDisabled = ifStyle('disabled');

type Props = {
  currentPage?: boolean;
};

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: ${spacingSm}px;
`;

type HeaderProps = {
  columns: number;
};

export const Header = styled.div<HeaderProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr ${({ columns }) => `repeat(${columns - 2}, 2fr)`} 1fr;
  align-items: center;
  margin-top: ${spacingMd}px;
  margin-bottom: ${spacingSm}px;
  padding: 0 ${spacingLg}px;
`;

export const Title = styled(Typography)<Props>`
  padding: ${spacingSm}px 0;
  color: ${isCurrentPage(brandPrimaryContrast, textMain)};
`;

export const AngleIcon = styled(Icon).attrs({
  fontSize: pxToRem(32),
})`
  color: ${textMain};
`;

type PropsIcon = {
  disabled: boolean;
};
export const WrapperIcon = styled.div<PropsIcon>`
  margin: 0 ${spacingLg}px;
  opacity: ${isDisabled(0.1, 1)};
  cursor: ${isDisabled('not-allowed', 'pointer')};
`;

export const BoxPage = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(50)};
  height: ${pxToRem(50)};
  border-radius: ${themeRadiusButton}px;
  background-color: ${isCurrentPage(brandPrimaryLight)};
  margin: 0 ${spacingXs}px;
  cursor: ${isCurrentPage('not-allowed', 'pointer')};
`;

export const WrapperPagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${spacingLg}px 0;
`;

export const Date = styled(Typography)`
  margin: 0 0 ${spacingSm}px ${spacingSm}px;
  padding: 0 ${spacingLg}px;
`;

export const LoadingBox = styled.div`
  padding-top: ${spacingLg}px;
  padding-bottom: ${spacingXxl}px;
`;

export const EmptyDataComponent = styled(EmptyData)``;
