import { action, makeObservable, observable } from '~/modules';

export default class UtilsStore {
  @observable
  currentProgressId = 1;

  constructor() {
    makeObservable(this);
  }

  @action
  setProgressId = async (id: number): Promise<void> => {
    this.currentProgressId = id;
  };
}
