import { ChangeEvent, FC, useEffect, useState } from 'react';
import { faEye, faEyeSlash, faMagnifyingGlass } from '~/assets';
import { CurrencyInputProps, If, TextInputType, isEmpty } from '~/modules';
import { KeyboardEvents, currencyMask } from '~/utils';
import {
  Errors,
  Icon,
  IconSearch,
  Input,
  InputTextArea,
  Label,
  Wrapper,
  WrapperInput,
} from './styles';

export type TextInputProps = Omit<TextInputType, 'type'> &
  CurrencyInputProps & {
    type?: string;
    label?: string;
    className?: string;
    errors?: string | boolean;
    onPressMagnifyingGlass?(): Promise<void> | void;
  };

const TextInput: FC<TextInputProps> = ({
  label,
  type = 'text',
  className,
  errors,
  variant = 'outlined',
  maskType,
  disabled,
  onPressMagnifyingGlass,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const handleEyes = () => {
    setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible);
  };
  const typeInput =
    type === 'password' ? (isPasswordVisible ? 'text' : 'password') : type;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.onChange) return;
    const value = currencyMask(e.target.value);
    e.target.value = value;
    props.onChange(e);
  };

  const handleOnChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!props.onChange) return;
    props.onChange(e as unknown as ChangeEvent<HTMLInputElement>);
  };

  const detectKeyDown = (event: KeyboardEvent) => {
    if (
      !!onPressMagnifyingGlass &&
      !disabled &&
      event.key === KeyboardEvents.ENTER
    ) {
      onPressMagnifyingGlass();
    }
  };

  useEffect(() => {
    document.addEventListener(KeyboardEvents.TYPE, detectKeyDown, true);
    return () => {
      document.removeEventListener(KeyboardEvents.TYPE, detectKeyDown, true);
    };
  }, [!disabled]);

  return (
    <Wrapper className={className} disabled={disabled}>
      <If condition={!isEmpty(label)}>
        <Label>{label}</Label>
      </If>

      <WrapperInput>
        <If condition={maskType !== 'currency' && type !== 'textarea'}>
          <Input
            {...props}
            fullWidth
            type={typeInput}
            variant={variant}
            maskType={maskType}
            disabled={disabled}
          />

          <If condition={type === 'filter'}>
            <IconSearch
              icon={faMagnifyingGlass}
              onClick={onPressMagnifyingGlass}
              onButton={!!onPressMagnifyingGlass}
            />
          </If>

          <If condition={type === 'password'}>
            <Icon
              icon={isPasswordVisible ? faEye : faEyeSlash}
              onClick={handleEyes}
            />
          </If>
        </If>

        <If condition={maskType === 'currency'}>
          <Input
            {...props}
            fullWidth
            type={typeInput}
            variant={variant}
            value={currencyMask(props.value as string)}
            onChange={onChange}
          />
        </If>

        <If condition={type === 'textarea'}>
          <InputTextArea
            id={props.id}
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            errors={!!errors}
            onChange={handleOnChangeTextArea}
          />
        </If>
      </WrapperInput>

      <Errors>{errors}</Errors>
    </Wrapper>
  );
};

export default TextInput;
