import { AccountType, AccountTypePt, BusinessTypes } from '../enums';

export const accountsColumns = ['Nome', 'Status', 'Tipo de Conta', 'Saldo', ''];

export const TypesSideAccount: SelectProps[] = [
  { label: AccountTypePt.CONTROL_CASH_IN, value: AccountType.CONTROL_CASH_IN },
  {
    label: AccountTypePt.CONTROL_CASH_OUT,
    value: AccountType.CONTROL_CASH_OUT,
  },
];

export const BusinessTypeSelect: SelectProps[] = [
  { label: BusinessTypes.MEI, value: BusinessTypes.MEI },
  { label: BusinessTypes.ME, value: BusinessTypes.ME },
  { label: BusinessTypes.LTDA, value: BusinessTypes.LTDA },
  { label: BusinessTypes.EIRELI, value: BusinessTypes.EIRELI },
  { label: BusinessTypes.SA, value: BusinessTypes.SA },
];
