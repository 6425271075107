import { FC } from 'react';
import { If } from '~/modules';
import { formatFirstLetteredUppercase, formatTimestampToDate } from '~/utils';
import StatusIndicator from '../StatusIndicator';
import {
  Card,
  LastActivity,
  LetteredAvatar,
  Name,
  Permission,
  Row,
} from './styles';

type Props = {
  name?: string;
  role?: Enums.UserRoles;
  status?: Enums.UserStatus;
  lastActivity?: number;
  onPress(): void;
};
const UsersRow: FC<Props> = ({
  name = '',
  lastActivity,
  status,
  role,
  onPress,
}) => {
  return (
    <Card onClick={onPress}>
      <Row>
        <LetteredAvatar name={name} />
        <Name>{name}</Name>
      </Row>
      <Row>
        <Permission>{formatFirstLetteredUppercase(role)}</Permission>
      </Row>
      <Row>
        <If condition={!!lastActivity}>
          <LastActivity>{formatTimestampToDate(lastActivity)}</LastActivity>
        </If>
      </Row>
      <Row>
        <StatusIndicator status={status} />
      </Row>
    </Card>
  );
};

export default UsersRow;
