import { TransactionsApi } from '~/api';
import { action, makeObservable, observable, persist } from '~/modules';

export default class TransactionStore {
  @observable
  transactionsList: ListResponse<Transaction> = {};

  @persist
  @observable
  transaction: Transaction = {} as Transaction;

  constructor() {
    makeObservable(this);
  }

  @action
  getAccountTransactions = async (
    values: GetTransactionsProps,
  ): Promise<void> => {
    this.transactionsList = await TransactionsApi.getAccountTransactions(
      values,
    );
  };

  @action
  getTransactionDetails = async (
    values: GetTransactionDetailsProps,
  ): Promise<void> => {
    this.transaction = await TransactionsApi.getTransactionDetails(values);
  };
}
