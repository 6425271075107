import {
  CSSObjectWithLabel,
  SelectComponent,
  Theme,
  getTheme,
  pxToRem,
  styled,
} from '~/modules';
import Typography from '../Typography';

const backgroundZ2 = getTheme('background.z2');
const backgroundZ4 = getTheme('background.z4');
const frostedGlass = getTheme('frosted glass.z0');
const reverseZ4 = getTheme('background.reverse.z4');
const brandPrimaryMain = getTheme('brand.primary.main');
const dangerLight = getTheme('danger.light');
const themeRadiusInput = getTheme('themeRadius.input');
const themeRadiusCard = getTheme('themeRadius.card');
const textLink = getTheme('text.link');
const textMain = getTheme('text.main');
const spacingMd = getTheme('spacing.md');
const spacingSm = getTheme('spacing.sm');

export const Wrapper = styled.div`
  width: 100%;
  flex-direction: column;
`;

type PropsStyleSelect = {
  selectProps: {
    color: string;
    backgroundZ2: string;
    backgroundZ4: string;
    backgroundPrimaryMain: string;
    reverseZ4: string;
    frostedGlass: string;
    themeRadiusInput: number;
    themeRadiusCard: number;
    textMain20: string;
    textMain: string;
    spacingMd: number;
    spacingSm: number;
  };
  isSelected: any;
  isFocused: any;
};
export const Select = styled(SelectComponent).attrs((props) => ({
  styles: {
    option: (provided: CSSObjectWithLabel, state: PropsStyleSelect) => ({
      ...provided,
      color: state.selectProps.textMain,
      backgroundColor: state.selectProps.backgroundZ2,
      width: '100%',
      display: 'flex',
      paddingLeft: state.selectProps.spacingSm,
      fontWeight: state.isSelected ? 'bold' : '500',
    }),
    control: (provided: CSSObjectWithLabel, state: PropsStyleSelect) => ({
      ...provided,
      width: '100%',
      height: pxToRem(48),
      display: 'flex',
      backgroundColor: state.selectProps.frostedGlass,
      borderRadius: 8,
      borderColor: state.selectProps.textMain20,
      color: state.selectProps.textMain,
    }),
    menuList: () => ({
      padding: 0,
      flexDirection: 'column',
      width: '100%',
    }),
    indicatorSeparator: () => false,
    placeholder: (provided: CSSObjectWithLabel, state: PropsStyleSelect) => ({
      ...provided,
      color: state.selectProps.reverseZ4,
    }),
    dropdownIndicator: (
      provided: CSSObjectWithLabel,
      state: PropsStyleSelect,
    ) => ({
      marginRight: state.selectProps.spacingMd,
      color: state.selectProps.reverseZ4,
    }),
    singleValue: (provided: CSSObjectWithLabel, state: PropsStyleSelect) => ({
      ...provided,
      color: state.selectProps.reverseZ4,
    }),
    input: (provided: CSSObjectWithLabel, state: PropsStyleSelect) => ({
      ...provided,
      color: state.selectProps.reverseZ4,
    }),
  },
  theme: (theme: Theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary50: '',
      primary: brandPrimaryMain(props),
    },
  }),
  color: textLink(props),
  frostedGlass: frostedGlass(props),
  backgroundZ2: backgroundZ2(props),
  backgroundZ4: backgroundZ4(props),
  backgroundPrimaryMain: brandPrimaryMain(props),
  themeRadiusInput: themeRadiusInput(props),
  themeRadiusCard: themeRadiusCard(props),
  textMain20: `${textMain(props)}20`,
  textMain: textMain(props),
  reverseZ4: reverseZ4(props),
  spacingMd: `${spacingMd(props)}px`,
  spacingSm: `${spacingSm(props)}px`,
}))<PropsStyleSelect>``;

export const Label = styled(Typography).attrs({
  variant: 'body1',
})`
  font-weight: 700;
`;

export const CustomLabel = styled(Typography)`
  font-weight: 500;
  margin: ${spacingSm}px 0;
`;

export const Error = styled(Typography).attrs({
  variant: 'caption',
})`
  color: ${dangerLight};
  margin-top: ${spacingSm}px;
`;
