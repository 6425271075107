import { FC, ReactNode } from 'react';
import { If } from '~/modules';
import { Title, Total, Wrapper } from './styles';

type Props = {
  children: ReactNode;
  totalElements?: number;
  className?: string;
};

const TitlePage: FC<Props> = ({ children, totalElements, className }) => (
  <Wrapper className={className}>
    <Title>{children}</Title>
    <If condition={!!totalElements}>
      <Total>{totalElements} no total</Total>
    </If>
  </Wrapper>
);

export default TitlePage;
