import { getTheme, ifStyle, styled } from '~/modules';

const backgroundZ3 = getTheme('background.z3');
const textMain = getTheme('text.main');

const spacingXxl = getTheme('spacing.xxl');
const borderWidthMin = getTheme('borderWidth.min');

const isOnBlur = ifStyle('blur');
const isHiddenBorder = ifStyle('hiddenBorder');
const isOffPaddingContent = ifStyle('offPaddingContent');

type Props = {
  blur?: boolean;
  hiddenBorder?: boolean;
  offPaddingContent?: boolean;
};

export const Wrapper = styled.div<Props>`
  min-height: 100vh;
  width: 100%;
  position: fixed;
  filter: ${isOnBlur('blur(8px)')};
`;

export const WrapperHeading = styled.div<Props>`
  flex-direction: column;
  border-bottom: ${isHiddenBorder(0, borderWidthMin)}px solid ${textMain}30;
  padding: 0 ${spacingXxl}px;
`;

export const ChildrenWrapper = styled.div<Props>`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background-color: ${backgroundZ3};
  filter: ${isOnBlur('blur(8px)')};
`;

export const Constrain = styled.div<Props>`
  flex: 1;
  overflow-y: auto;
  padding: 0 ${isOffPaddingContent(0, spacingXxl)}px;
  pointer-events: ${isOnBlur('none')};
`;
