import { FC } from 'react';
import { Text, WrapperItem } from './styles';

type Props = {
  id: string;
  icon: JSX.Element;
  text: string;
  activatedRoute: boolean;
  onNavigate(): void;
};

const ItemMenu: FC<Props> = ({
  id,
  icon,
  activatedRoute,
  text,
  onNavigate,
}) => {
  return (
    <WrapperItem id={id} activatedRoute={activatedRoute} onClick={onNavigate}>
      {icon}
      <Text>{text}</Text>
    </WrapperItem>
  );
};

export default ItemMenu;
