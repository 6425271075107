import { Button, SceneWrapper, Typography } from '~/components';
import { Icon, getTheme, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';

const backgroundZ4 = getTheme('background.z4');
const textMain = getTheme('text.main');

const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');

const themeRadiusCard = getTheme('themeRadius.card');

const boxShadow = getTheme('boxShadow.z4');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const Content = styled.div`
  width: 100%;
  flex-wrap: wrap;

  @media ${mqDevices.inMobileAndTablet} {
    justify-content: center;
  }
`;

export const WrapperCard = styled.div`
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: ${pxToRem(354)};
  height: ${pxToRem(390)};
  padding: ${spacingXl}px;
  border-radius: ${themeRadiusCard}px;
  background-color: ${backgroundZ4};
  margin-top: ${spacingXl}px;
  margin-right: ${spacingLg}px;
  ${boxShadow}

  @media ${mqDevices.inMobileAndTablet} {
    width: ${pxToRem(500)};
    height: ${pxToRem(450)};
  }
`;

export const IconComponent = styled(Icon).attrs({
  fontSize: pxToRem(60),
})`
  color: ${textMain};
`;

export const Title = styled(Typography).attrs({ variant: 'h5' })`
  font-weight: bold;
  text-align: center;
  opacity: 0.8 !important;
`;

export const Description = styled(Typography).attrs({ variant: 'body1' })`
  text-align: center;
`;

export const ButtonComponent = styled(Button)`
  width: 100%;
  min-height: ${pxToRem(51)};
`;
