import { FC } from 'react';
import { faIdCardSolid, faSearch } from '~/assets';
import { If, currencyParser } from '~/modules';
import {
  AccountType,
  AccountTypePt,
  ProductStatus,
  ProductStatusPt,
  parseEnumToPt,
} from '~/utils';
import StatusIndicator from '../StatusIndicator';
import {
  AccountTypeText,
  Balance,
  Card,
  IconCard,
  IconSearch,
  Name,
  Row,
  SearchIconBox,
} from './styles';

type Props = {
  name?: string;
  type?: AccountType;
  status?: ProductStatus;
  balance?: number;
  offIcon?: boolean;
  onPress?(): void;
};

const AccountRow: FC<Props> = ({
  name = '',
  status,
  balance,
  type,
  offIcon = false,
  onPress,
}) => {
  return (
    <Card fourItens={!onPress}>
      <Row>
        <If condition={!offIcon}>
          <IconCard icon={faIdCardSolid} />
        </If>
        <Name>{name}</Name>
      </Row>
      <Row>
        <StatusIndicator status={parseEnumToPt(status, ProductStatusPt)} />
      </Row>
      <Row>
        <AccountTypeText>{parseEnumToPt(type, AccountTypePt)}</AccountTypeText>
      </Row>
      <Row>
        <Balance>{currencyParser(balance || '')}</Balance>
      </Row>
      <If condition={!!onPress}>
        <Row>
          <SearchIconBox onClick={onPress}>
            <IconSearch icon={faSearch} />
          </SearchIconBox>
        </Row>
      </If>
    </Card>
  );
};

export default AccountRow;
