import { FC } from 'react';
import {
  ProductStatus,
  ProductStatusPt,
  UserStatus,
  parseEnumToPt,
} from '~/utils';
import { BallGreen, BallRed, BallYellow, Text, Wrapper } from './styles';

type Props = {
  status?: ProductStatus | UserStatus;
  textColor?: string;
};

const StatusLabel: FC<Props> = ({
  status = ProductStatus.ACTIVE,
  textColor,
}) => {
  const getIconStatus = {
    [ProductStatus.ACTIVE]: <BallGreen />,
    [ProductStatus.CANCELED]: <BallRed />,
    [ProductStatus.INACTIVE]: <BallRed />,

    [UserStatus.ACTIVE]: <BallGreen />,
    [UserStatus.BLOCKED]: <BallRed />,
    [UserStatus.CANCELLED]: <BallRed />,
    [UserStatus.PENDING]: <BallYellow />,
  };

  return (
    <Wrapper>
      {getIconStatus[status]}
      <Text textColor={textColor}>
        {parseEnumToPt(status, ProductStatusPt || UserStatus)}
      </Text>
    </Wrapper>
  );
};

export default StatusLabel;
