import { ListPagination, ResponseError } from '~/utils';
import request from '../request';

export const getAccountTransactions = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
  startDate,
  endDate,
  transactionType,
  accountId,
}: GetTransactionsProps): Promise<ListResponse<Transaction>> => {
  try {
    const { data } = await request.get(`/accounts/${accountId}/events`, {
      params: {
        endDate,
        page,
        size,
        startDate,
        transactionType,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getTransactionDetails = async ({
  transactionId,
  accountId,
}: GetTransactionDetailsProps): Promise<Transaction> => {
  try {
    const { data } = await request.get(
      `/accounts/${accountId}/events/${transactionId}`,
    );
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};
