import { Provider } from 'mobx-react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import { Helmet } from '~/modules';
import { ScrollToTop } from './modules';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import { GoogleTagManager, Sentry } from './services';
import * as serviceWorker from './serviceWorker';
import store from './stores';
import { ThemeProvider } from './theme';
import 'react-toastify/dist/ReactToastify.css';
import GlobalStyle from './theme/global';
import { ToastContainer } from './utils';

const { REACT_APP_ENV } = process.env;

const container = document.getElementById('app');
const root = createRoot(container!);

if (REACT_APP_ENV === 'PROD') {
  Sentry.init();

  GoogleTagManager.init();
}

console.warn = () => null;

root.render(
  <Provider rootStore={store}>
    <ThemeProvider theme={store.theme}>
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop>
          <StylesProvider injectFirst>
            <Routes />
          </StylesProvider>

          <Helmet>
            <title>Gringotts Portal</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="theme-color" content="#212121" />
            <meta name="handheldFriendly" content="true" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="description" content="Gringotts Portal" />
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="black-translucent"
            />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
          </Helmet>
          <GlobalStyle theme={store.theme} />
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
