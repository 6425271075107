import { Icon, getTheme, pxToRem, styled } from '~/modules';
import Button from '../Button';
import Typography from '../Typography';

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingXl = getTheme('spacing.xl');

const borderRadiusMax = getTheme('borderRadius.max');

export const Title = styled(Typography).attrs({ variant: 'h5' })`
  font-weight: bold;
  margin-bottom: ${spacingXl}px;
`;

export const WrapperOptions = styled.div`
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: ${spacingXl}px;
`;

export const OptionButton = styled(Button)`
  width: max-content;
  min-width: ${pxToRem(200)};
  padding: 0 ${spacingMd}px;
  height: ${pxToRem(60)};
  border-radius: ${borderRadiusMax}px;
  margin-right: ${spacingSm}px;
  margin-bottom: ${spacingSm}px;
`;

export const IconOption = styled(Icon).attrs({
  fontSize: pxToRem(20),
})`
  margin-right: ${spacingMd}px;
`;
