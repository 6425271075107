import { FC } from 'react';
import { faPlus } from '~/assets';
import {
  AccountRow,
  ClientCardInfo,
  OptionButton,
  OptionsList,
  PaginatedList,
} from '~/components';
import { Routes } from '~/routes';
import {
  PersonType,
  accountsColumns,
  formatAddress,
  formatPhone,
  getActiveAddress,
} from '~/utils';
import { ContentBox, PaginatedListBox, Title, Wrapper } from './styles';

type Props = {
  client: Client;
  onShowClientData(): void;
  onPressAccount(account: Account): void;
  onNavigate(route: Routes): void;
};

const ClientAccounts: FC<Props> = ({
  client,
  onShowClientData,
  onPressAccount,
  onNavigate,
}) => {
  const activeAddress = getActiveAddress(client.addresses);

  return (
    <Wrapper titlePage="Contas do cliente">
      <ContentBox>
        <ClientCardInfo
          name={client?.fullName}
          email={client?.mail}
          phone={formatPhone(client?.phone)}
          address={formatAddress(activeAddress)}
          onPressSeeData={onShowClientData}
        />
        <PaginatedListBox>
          <OptionsList condition={client.personType === PersonType.PJ}>
            <OptionButton
              id="btn_add_side_account"
              label="Cadastrar Conta de Controle"
              icon={faPlus}
              onPress={() => onNavigate(Routes.SIDE_ACCOUNT_REGISTER)}
            />
          </OptionsList>
          <Title>Contas Vinculadas</Title>
          <PaginatedList
            columns={accountsColumns}
            list={client.accounts}
            showPagination={false}
            renderList={(account) => (
              <AccountRow
                balance={account?.balance}
                name={account?.product?.name}
                status={account?.status}
                type={account?.type}
                onPress={() => onPressAccount(account)}
              />
            )}
            titleEmptyData="Nenhuma conta vinculada"
            descriptionEmptyData="O cliente ainda não possui nenhuma conta vinculada"
          />
        </PaginatedListBox>
      </ContentBox>
    </Wrapper>
  );
};

export default ClientAccounts;
