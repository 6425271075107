import { FC, useMemo } from 'react';
import { If, isEmpty } from '~/modules';
import { CustomLabel, Error, Select, Wrapper } from './styles';

type Props = {
  id: string;
  name: string;
  placeholder?: string;
  options: SelectProps[];
  value: string;
  label?: string;
  secondary?: boolean;
  error?: string | boolean;
  className?: string;
  onChange(props: ChangeFieldValue): void;
};

const SelectContainer: FC<Props> = ({
  id,
  name,
  options,
  placeholder,
  value,
  label = '',
  secondary = false,
  error,
  className,
  onChange,
}) => {
  const defaultValue = useMemo(() => {
    return options ? options.find((option) => option.value === value) : '';
  }, [options, value]);

  const handleChangeOption = ({ value }: SelectProps) => {
    onChange({ name, value });
  };

  const renderSelect = (defaultValue?: SelectProps | string) => {
    return (
      <Select
        menuPlacement="auto"
        value={defaultValue || ''}
        id={id}
        secondary={secondary}
        name={name}
        placeholder={placeholder}
        options={options}
        onChange={handleChangeOption}
      />
    );
  };

  return (
    <Wrapper className={className}>
      <If condition={!isEmpty(label)}>
        <CustomLabel>{label}</CustomLabel>
      </If>

      {renderSelect(defaultValue)}

      <Error>{error}</Error>
    </Wrapper>
  );
};
export default SelectContainer;
