import {
  ListPagination,
  ProductStatus,
  ResponseError,
  TypeAccountsFilter,
} from '~/utils';
import request from '../request';

export const getProducts = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
}: GetProductsProps): Promise<ListResponse<Product>> => {
  try {
    const { data } = await request.get('/products', {
      params: {
        page,
        size,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getMCC = async (code?: string): Promise<MCCList> => {
  try {
    const { data } = await request.get('/mcc-codes', {
      params: {
        code,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const registerProduct = async ({
  description,
  ...rest
}: RegisterProduct): Promise<Product> => {
  try {
    const { data } = await request.post('/products', {
      description: description.replace('\n', ''),
      ...rest,
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getControlAccounts = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
  productId,
}: ListControlAccountsProps): Promise<ListResponse<Account>> => {
  try {
    const { data } = await request.get(`/products/${productId}/accounts`, {
      params: {
        page,
        size,
        type: TypeAccountsFilter.CONTROL,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const setStatusProduct = async (
  productId: string,
  status?: ProductStatus,
): Promise<Product> => {
  try {
    const { data } = await request.patch(`/products/${productId}/status`, {
      status,
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};
