import { FC, useEffect, useState } from 'react';
import { HeaderModal, PaginatedList, ProductRow } from '~/components';
import { productsColumns, showAlert, useStores } from '~/utils';
import { Content, Wrapper } from './styles';

type Props = {
  isOpen: boolean;
  handleClose(): void;
  onSelectProduct(product: Product): void;
};

const ModalProducts: FC<Props> = ({ isOpen, handleClose, onSelectProduct }) => {
  const {
    products: { productsList, getProducts },
  } = useStores();

  const [productsLoading, setProductsLoading] = useState(false);

  const onGetProducts = async (values: GetProductsProps) => {
    try {
      setProductsLoading(true);
      await getProducts(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setProductsLoading(false);
    }
  };

  useEffect(() => {
    onGetProducts({});
  }, []);

  return (
    <Wrapper isOpen={isOpen}>
      <HeaderModal title="Vincular Produto" onPressClose={handleClose} />
      <Content>
        <PaginatedList
          columns={productsColumns}
          list={productsList.content}
          totalPages={productsList.paging?.totalPages}
          currentPage={productsList.paging?.page}
          isLoading={productsLoading}
          onChangePage={(page) => onGetProducts({ page })}
          renderList={(product) => (
            <ProductRow
              sideAccount
              description={product?.description}
              name={product?.name}
              status={product?.status}
              type={product?.type}
              showInfo={product?.showInfo}
              onPress={() => onSelectProduct(product)}
            />
          )}
          titleEmptyData="Sem produtos disponíveis"
        />
      </Content>
    </Wrapper>
  );
};

export default ModalProducts;
