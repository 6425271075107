import { FC, useState } from 'react';
import { faPlus, faXmark } from '~/assets';
import { FooterButtons } from '~/components';
import { FormikProps, isEmpty, useFormikContext } from '~/modules';
import { StepsSideAccount, TypesSideAccount, maskCpfOrCnpj } from '~/utils';
import ModalProducts from './ModalProducts';
import {
  Content,
  Error,
  IconComponent,
  Input,
  Label,
  ProductInput,
  SelectInputComponent,
  TitlePageComponent,
  Wrapper,
  WrapperProduct,
} from './styles';

type Props = {
  client: Client;
  nameProduct: string;
  setNameProduct(name: string): void;
  onNavigateId(id: number): void;
};

const DataAccount: FC<Props> = ({
  client,
  nameProduct,
  onNavigateId,
  setNameProduct,
}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
  }: FormikProps<SideAccountRegister> = useFormikContext();

  const [productName, setProductName] = useState(nameProduct);
  const [onModalProducts, setOnModalProducts] = useState(false);

  const handleOnModalProducts = () =>
    setOnModalProducts((onModalProducts) => !onModalProducts);

  const handleSelectProduct = async (product: Product) => {
    setProductName(product.name || '');
    setFieldValue('productId', product?.id);
    setFieldValue('personId', client.id);
    setNameProduct(product.name || '');
    handleOnModalProducts();
  };

  const handleClear = async () => {
    resetForm();
    setProductName('');
    setFieldValue('productId', '');
    setNameProduct('');
  };

  return (
    <Wrapper>
      <Content>
        <TitlePageComponent>Insira os dados da conta</TitlePageComponent>
        <WrapperProduct>
          <Label>Produto</Label>
          <ProductInput onClick={handleOnModalProducts}>
            <IconComponent icon={isEmpty(productName) ? faPlus : faXmark} />
            {isEmpty(productName) ? 'Selecionar Produto' : productName}
          </ProductInput>
          <Error>{touched.type && errors?.productId}</Error>
        </WrapperProduct>
        <Input
          label="CNPJ"
          placeholder="00.000.000/0000-00"
          id="document"
          name="document"
          disabled
          value={maskCpfOrCnpj(client.document)}
          onChange={handleChange}
        />
        <SelectInputComponent
          label="Tipo de conta"
          placeholder="Selecione o tipo da conta"
          id="type"
          name="type"
          error={touched.type && errors?.type}
          value={values.type}
          options={TypesSideAccount}
          onChange={({ name, value }) => setFieldValue(name, value)}
        />
        <Input
          label="Cod. Interno (opcional)"
          placeholder="Informe um código interno para a conta de controle"
          id="internalAccountId"
          name="internalAccountId"
          error={touched.internalAccountId && errors?.internalAccountId}
          value={values.internalAccountId}
          onChange={handleChange}
        />
      </Content>
      <FooterButtons
        firstBtn={{
          id: 'btn_clear',
          label: 'Limpar',
          onPress: () => handleClear(),
        }}
        secondaryBtn={{
          id: 'btn_confirm',
          label: 'Próximo',
          onPress: () => onNavigateId(StepsSideAccount.META_DATA),
        }}
      />
      <ModalProducts
        isOpen={onModalProducts}
        handleClose={handleOnModalProducts}
        onSelectProduct={(product) => handleSelectProduct(product)}
      />
    </Wrapper>
  );
};

export default DataAccount;
