export enum AlertMessages {
  TITLE_PAGE = 'Adicione um nome para a página',
  TITLE_FILTER = 'Adicione um titulo para o filtro',
  SUCCESS_REGISTER_USER = 'Usuário cadastrado com sucesso!',
  SUCCESS_CHANGE_PASSWORD = 'Senha alterada com sucesso!',
  INACTIVE_ACCOUNT_MESSAGE = 'Após inativada, a conta não poderá ser utilizada pelo cliente.',
  ACTIVE_ACCOUNT_MESSAGE = 'Após ativada, a conta volta a estar disponível para as operações do cliente.',
  CANCEL_ACCOUNT_MESSAGE = 'Após cancelada, a conta não poderá ser utilizada pelo cliente. Como administrador você não conseguirá reestabelecer este acesso.',
  SUCCESS_INACTIVE_ACCOUNT = 'Conta inativada com sucesso.',
  SUCCESS_ACTIVE_ACCOUNT = 'Conta ativada com sucesso.',
  SUCCESS_CANCEL_ACCOUNT = 'Conta cancelada com sucesso.',
  SUCCESS_EDIT_USER = 'Dados do usuário editados com sucesso',
  BLOCK_USER_MESSAGE = 'Quando bloqueado, o usuário perderá o acesso ao portal até que seja desbloqueado.',
  SUCCESS_BLOCK_USER = 'Usuário bloqueado com sucesso',
  UNBLOCK_USER_MESSAGE = 'Quando desbloqueado, o usuário voltará a ter acesso ao portal.',
  SUCCESS_UNBLOCK_USER = 'Usuário desbloqueado com sucesso.',
  CANCEL_USER_MESSAGE = 'Quando cancelado, o usuário perderá o acesso ao portal. Como administrador você não conseguirá reestabelecer este acesso.',
  SUCCESS_CANCEL_USER = 'Usuário cancelado com sucesso.',
  ERROR_ADD_MCC = 'MCC já adicionado',
  SUCCESS_REGISTER_PRODUCT = 'Produto cadastrado com sucesso.',
  ACTIVE_PRODUCT = 'Quando ativado, o produto irá voltar a transacionar.',
  INACTIVE_PRODUCT = 'Quando inativado, o produto irá parar de transacionar, somente voltando a operar mediante a ativação.',
  SUCCESS_INACTIVE_PRODUCT = 'Produto inativado com sucesso.',
  SUCCESS_ACTIVE_PRODUCT = 'Produto ativado com sucesso.',
  SUCCESS_SIDE_ACCOUNT_REGISTER = 'Conta de controle cadastrada com sucesso.',
  SUCCESS_COMPANY_REGISTER = 'Cliente cadastrado com sucesso.',
  SUCCESS_RESET_PASSWORD = 'Senha redefinida com sucesso.',
}
