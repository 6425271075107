import {
  Icon as IconComponent,
  Modal,
  getTheme,
  ifStyle,
  pxToRem,
  styled,
} from '~/modules';
import Touchable from '../Touchable';
import Typography from '../Typography';

const textMain = getTheme('text.main');
const backgroundZ3 = getTheme('background.z3');
const backgroundZ4 = getTheme('background.z4');
const themeRadiusButton = getTheme('themeRadius.button');
const themeRadiusCard = getTheme('themeRadius.card');
const boxShadowZ3 = getTheme('boxShadow.z3');
const spacingMd = getTheme('spacing.md');
const spacingSm = getTheme('spacing.sm');
const spacingXs = getTheme('spacing.xs');

const isDisabled = ifStyle('disabled');

type Props = {
  disabled?: boolean;
};

export const Wrapper = styled(Modal).attrs({
  style: {
    overlay: {
      display: 'flex',
      position: 'fixed',
      zIndex: 6,
      backgroundColor: 'none',
      justifyContent: 'flex-end',
      top: 0,
      left: 0,
    },
  },
})`
  display: flex;
  margin-right: ${pxToRem(40)};
  margin-top: ${pxToRem(85)};
  flex-direction: column;
  align-items: center;
  width: ${pxToRem(260)};
  height: fit-content;
  background-color: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;
  padding: ${spacingMd}px;
  ${boxShadowZ3};
`;

export const Item = styled.div<Props>`
  width: 100%;
  margin: ${spacingMd}px 0;
  padding: 0 ${spacingSm}px;
  opacity: ${isDisabled(0.5)};
  cursor: ${isDisabled('not-allowed', 'pointer')};
`;

export const Icon = styled(IconComponent)`
  font-size: ${pxToRem(22)};
  margin-right: ${spacingMd}px;
  color: ${textMain};
`;

export const IconLogout = styled(Icon)`
  margin-left: ${spacingXs}px;
`;

export const Text = styled(Typography)`
  font-weight: 500;
`;

export const ItemLogout = styled(Touchable)`
  width: 100%;
  margin-top: ${spacingMd}px;
  opacity: ${isDisabled(0.5)};
  padding: ${spacingSm}px;
  background-color: ${backgroundZ3};
  border-radius: ${themeRadiusButton}px;
`;
