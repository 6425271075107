import { currencyParser } from '~/modules';

export const currencyMask = (text: string, showSymbol = true) => {
  if (!text) return `${showSymbol ? 'R$ ' : ''}0,00`;
  try {
    const numbers = parseFloat(text.replace(/\D/g, ''));
    let value = `${(numbers / 100).toFixed(2)}`;
    value = value.replace('.', ',');
    value = value.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    value = value.replace(/(\d)(\d{3}),/g, '$1.$2,');
    return showSymbol ? `R$ ${value}` : value;
  } catch {
    return '';
  }
};

export const removeCurrencySymbol = (text: string) => {
  if (!text) return '';
  return text.replace('R$ ', '');
};

export const formatToCurrency = (
  value?: string | number,
  showSymbol = true,
) => {
  return showSymbol
    ? currencyParser(value || '')
    : removeCurrencySymbol(currencyParser(value || ''));
};
