import { FC, useEffect, useMemo, useState } from 'react';
import {
  ModalConfirmation,
  ModalListMetaData,
  ModalTransactionDetails,
} from '~/components';
import { If, isEmpty, observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import {
  AlertMessages,
  EventOptionsAccount,
  ListPagination,
  formatGroupDate,
  formatToApiDate,
  showAlert,
  useStores,
} from '~/utils';
import AccountDetails from './AccountDetails';

type GetTransactions = Omit<GetTransactionsProps, 'accountId'>;

const AccountDetailsContainer: FC = () => {
  const {
    clients: { selectedAccount, setStatusAccount },
    transactions: {
      transactionsList,
      transaction,
      getAccountTransactions,
      getTransactionDetails,
    },
  } = useStores();
  const navigate = useNavigate();

  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState<CalendarDay>();
  const [selectedEndDate, setSelectedEndDate] = useState<CalendarDay>();
  const [textModalConfirmation, setTextModalConfirmation] =
    useState<AlertMessages>('' as AlertMessages);
  const [optionSelected, setOptionSelected] = useState<EventOptionsAccount>();

  const [isOpenListMetaData, setIsOpenListMetaData] = useState(false);
  const handleOpenListMetaData = () =>
    setIsOpenListMetaData((isOpenListMetaData) => !isOpenListMetaData);

  const [isOpenTransactionDetail, setIsOpenTransactionDetail] = useState(false);
  const handleOpenTransactionDetail = () => {
    setIsOpenTransactionDetail(
      (isOpenTransactionDetail) => !isOpenTransactionDetail,
    );
  };

  const handleOnPressTransaction = async (transactionId: string) => {
    try {
      await getTransactionDetails({
        accountId: selectedAccount.id,
        transactionId,
      });
      handleOpenTransactionDetail();
    } catch ({ message }) {
      showAlert({ message });
    }
  };

  const formatStartEndDate = () => {
    let startDate = '';
    let endDate = '';
    if (selectedStartDate) {
      startDate = formatToApiDate(selectedStartDate.dayDate.toDate());
      if (selectedEndDate) {
        endDate = formatToApiDate(selectedEndDate.dayDate.toDate());
      }
    }
    return [startDate, endDate];
  };

  const getTransactions = async ({
    page,
    startDate,
    endDate,
    transactionType,
  }: GetTransactions) => {
    setIsTransactionsLoading(true);
    try {
      const [formattedStartDate, formattedEndDate] = formatStartEndDate();
      await getAccountTransactions({
        page,
        transactionType,
        accountId: selectedAccount.id,
        endDate: endDate ?? formattedEndDate,
        startDate: startDate ?? formattedStartDate,
      });
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsTransactionsLoading(false);
    }
  };

  const onSearch = async () => {
    await getTransactions({
      page: ListPagination.INITIAL_PAGE,
    });
  };

  const onClearFilter = async () => {
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    await getTransactions({
      startDate: '',
      endDate: '',
      page: ListPagination.INITIAL_PAGE,
    });
  };

  const handleOnpressOption = (
    option: EventOptionsAccount,
    text: AlertMessages,
  ) => {
    setOptionSelected(option);
    setTextModalConfirmation(text);
  };

  const handleOptionSelected = async () => {
    const message = await setStatusAccount(selectedAccount.id, optionSelected);
    setTextModalConfirmation('' as AlertMessages);
    showAlert({ message, type: 'success' });
  };

  const transactionsGroups: GroupDateProps<Transaction>[] = useMemo(() => {
    return formatGroupDate({
      content: transactionsList.content,
      renderPeriodValue: ({ createdAt }) => createdAt,
    });
  }, [transactionsList.content]);

  useEffect(() => {
    if (!isEmpty(selectedAccount)) {
      getTransactions({});
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (isEmpty(selectedAccount)) {
      navigate(Routes.CLIENT_ACCOUNTS);
    }
  }, [selectedAccount]);

  return (
    <>
      <AccountDetails
        account={selectedAccount}
        isTransactionsLoading={isTransactionsLoading}
        transactionsGroups={transactionsGroups}
        listPaging={transactionsList.paging}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        onPressTransaction={handleOnPressTransaction}
        onPressAditionalInfo={handleOpenListMetaData}
        onPressOption={handleOnpressOption}
        onChangePage={(page) => getTransactions({ page })}
        onSelectEndDate={setSelectedEndDate}
        onSelectStartDate={setSelectedStartDate}
        onClearFilter={onClearFilter}
        onSearch={onSearch}
      />
      <ModalListMetaData
        isOpen={isOpenListMetaData}
        metaDataList={selectedAccount?.metadata}
        handleClose={handleOpenListMetaData}
      />
      <If condition={!isEmpty(transaction)}>
        <ModalTransactionDetails
          isOpen={isOpenTransactionDetail}
          transactionDetail={transaction}
          handleClose={handleOpenTransactionDetail}
        />
      </If>
      <ModalConfirmation
        isOpen={!isEmpty(textModalConfirmation)}
        description={textModalConfirmation}
        onConfirm={handleOptionSelected}
        onCancel={() => setTextModalConfirmation('' as AlertMessages)}
      />
    </>
  );
};

export default observer(AccountDetailsContainer);
