import { FC } from 'react';
import { faPlus } from '~/assets';
import { PaginatedList, ProductRow, TitlePage } from '~/components';
import { Routes } from '~/routes';
import { productsColumns } from '~/utils';
import {
  ButtonRegisterProduct,
  ContentBox,
  IconPlus,
  TopBox,
  Wrapper,
} from './styles';

type Props = {
  isProductsLoading: boolean;
  productsList: ListResponse<Product>;
  isUserEspectator: boolean;
  onChangePage(page: number): void;
  onPressProduct(product: Product): void;
  onNavigate(route: Routes): void;
};

const Products: FC<Props> = ({
  productsList,
  isProductsLoading,
  isUserEspectator,
  onChangePage,
  onPressProduct,
  onNavigate,
}) => (
  <Wrapper titlePage="Produtos">
    <ContentBox>
      <TopBox>
        <TitlePage totalElements={productsList.paging?.totalElements}>
          Produtos
        </TitlePage>
        <ButtonRegisterProduct
          id="btn_register_product"
          disabled={isUserEspectator}
          onPress={() => onNavigate(Routes.PRODUCT_REGISTER)}
          secondary
        >
          <IconPlus icon={faPlus} />
          Cadastrar Produto
        </ButtonRegisterProduct>
      </TopBox>

      <PaginatedList
        columns={productsColumns}
        list={productsList.content}
        totalPages={productsList.paging?.totalPages}
        currentPage={productsList.paging?.page}
        isLoading={isProductsLoading}
        onChangePage={onChangePage}
        renderList={(product) => (
          <ProductRow
            description={product?.description}
            name={product?.name}
            status={product?.status}
            type={product?.type}
            showInfo={product?.showInfo}
            onPress={() => onPressProduct(product)}
          />
        )}
        titleEmptyData="Sem produtos disponíveis"
      />
    </ContentBox>
  </Wrapper>
);

export default Products;
