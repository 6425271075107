import { Filter, SceneWrapper, Typography } from '~/components';
import { getTheme, styled } from '~/modules';

const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXxl = getTheme('spacing.xxl');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const Content = styled.div`
  flex: 1;
  flex-direction: column;
  padding-top: ${spacingXxl}px;
`;

export const Description = styled(Typography).attrs({ variant: 'body1' })`
  margin-top: ${spacingMd}px;
  margin-bottom: ${spacingLg}px;
`;

export const PermissionTypeText = styled(Typography).attrs({ variant: 'h5' })`
  font-weight: bold;
  margin-top: ${spacingXxl}px;
`;

export const FilterComponent = styled(Filter)`
  padding-bottom: 0;
  margin-right: ${spacingLg}px;
`;
