import { EventOptionsAccount, ResponseError } from '~/utils';
import request from '../request';

export const getClientAccounts = async (document: string): Promise<Client> => {
  try {
    const { data } = await request.get(`/persons/${document}/accounts`);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const setStatusAccount = async (
  accountId: string,
  status?: EventOptionsAccount,
): Promise<Account> => {
  try {
    const { data } = await request.patch(`/accounts/${accountId}/status`, {
      status,
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const sideAccountRegister = async (
  values: SideAccountRegister,
): Promise<Account> => {
  try {
    const { data } = await request.post(`/side-accounts`, values);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const companyRegister = async (
  values: CompanyRegister,
): Promise<Client> => {
  try {
    const { data } = await request.post(`/companies`, values);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};
