import {
  Icon as IconComponent,
  TextInput as TextInputComponent,
  getTheme,
  ifStyle,
  pxToRem,
  styled,
} from '~/modules';
import Typography from '../Typography';

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');

const dangerLight = getTheme('danger.light');
const textMain = getTheme('text.main');
const frostedGlass = getTheme('frosted glass.z0');

const themeRadiusInput = getTheme('themeRadius.input');
const borderWidthMin = getTheme('borderWidth.min');

const isDisabled = ifStyle('disabled');
const isOnButton = ifStyle('onButton');
const isError = ifStyle('errors');

type Props = {
  disabled?: boolean;
  onButton?: boolean;
  errors?: boolean;
};

export const Wrapper = styled.div<Props>`
  flex-direction: column;
  width: 100%;
  opacity: ${isDisabled(0.6)};
`;

export const Input = styled(TextInputComponent)<Props>`
  width: 100%;
  pointer-events: ${isDisabled('none')};

  input {
    height: ${pxToRem(5)};
    padding-right: ${pxToRem(40)};
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${borderWidthMin}px solid ${textMain}35;
  }
  input::-webkit-input-placeholder {
    color: ${textMain};
    opacity: 0.5;
    font-family: 'Poppins', sans-serif;
  }

  .MuiInputBase-root,
  .MuiOutlinedInput-root {
    font-family: 'Poppins', sans-serif;
    background: ${frostedGlass};
    border-radius: ${themeRadiusInput}px;
    color: ${textMain};

    &:hover fieldset {
      border: ${borderWidthMin}px solid ${textMain}35;
    }

    &.Mui-focused fieldset {
      border: ${borderWidthMin}px solid ${textMain}35;
    }
  }
`;

export const InputTextArea = styled.textarea<Props>`
  width: 100%;
  height: ${pxToRem(154)};
  max-height: ${pxToRem(300)};
  border: ${borderWidthMin}px solid ${textMain}30;
  border-color: ${isError(dangerLight)};
  color: ${textMain};
  font-family: 'Poppins', sans-serif;
  background: ${frostedGlass};
  border-radius: ${themeRadiusInput}px;
  margin: ${spacingMd}px 0;
  padding: ${spacingSm}px;

  &::-webkit-input-placeholder {
    color: ${textMain};
    opacity: 0.5;
    font-family: 'Poppins', sans-serif;
  }
`;

export const Label = styled(Typography)`
  font-weight: 500;
  margin: ${spacingSm}px 0 -${spacingSm}px 0;
`;

export const Errors = styled(Typography).attrs({
  variant: 'caption',
})`
  margin-top: -${spacingSm}px;
  color: ${dangerLight};
`;

export const Icon = styled(IconComponent)`
  align-self: center;
  font-size: ${pxToRem(16)};
  position: absolute;
  right: ${pxToRem(15)};
  top: 32px;
  color: ${textMain};
  opacity: 0.5;
  cursor: pointer;
`;

export const IconSearch = styled(IconComponent)<Props>`
  align-self: center;
  position: absolute;
  font-size: ${pxToRem(16)};
  right: ${pxToRem(15)};
  top: 32px;
  color: ${textMain};
  cursor: ${isOnButton('pointer')};
`;

export const WrapperInput = styled.div`
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
