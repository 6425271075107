import { FC, useState } from 'react';
import { If, isEmpty } from '~/modules';
import { SectionsInfoAll } from '~/utils';
import EmptyData from '../EmptyData';
import HeaderModal from '../HeaderModal';
import {
  Card,
  Column,
  Content,
  Description,
  DescriptionMCC,
  SectionItem,
  SectionsBar,
  Title,
  TitleMCC,
  Value,
  Wrapper,
  WrapperSections,
  WrapperValue,
} from './styles';

type Props = {
  isOpen: boolean;
  metaDataList?: MetaData[];
  mccList?: MCC[];
  onSections?: boolean;
  handleClose(): void;
};

const ModalListMetaData: FC<Props> = ({
  isOpen,
  metaDataList,
  onSections = false,
  mccList,
  handleClose,
}) => {
  const [onSection, setOnSection] = useState(SectionsInfoAll.META_DATA);

  return (
    <Wrapper isOpen={isOpen} onClose={handleClose}>
      <HeaderModal title="Informações Adicionais" onPressClose={handleClose} />
      <If condition={onSections}>
        <WrapperSections>
          <SectionsBar>
            <SectionItem
              active={onSection === SectionsInfoAll.META_DATA}
              onClick={() => setOnSection(SectionsInfoAll.META_DATA)}
            >
              Metadata
            </SectionItem>
            <SectionItem
              active={onSection === SectionsInfoAll.MCC}
              onClick={() => setOnSection(SectionsInfoAll.MCC)}
            >
              MCC
            </SectionItem>
          </SectionsBar>
        </WrapperSections>
      </If>
      <Content>
        <If condition={onSection !== SectionsInfoAll.MCC}>
          <If condition={!isEmpty(metaDataList)}>
            <Column>
              <Title>Descrição</Title>
              <Title>Valor</Title>
            </Column>
            {metaDataList?.map(({ key, value }, index) => {
              return (
                <Card key={index}>
                  <Description>{key}</Description>
                  <WrapperValue>
                    <Value>{value}</Value>
                  </WrapperValue>
                </Card>
              );
            })}
          </If>
          <If condition={isEmpty(metaDataList)}>
            <EmptyData title="Sem metadados cadastrados" description="" />
          </If>
        </If>

        <If condition={onSection === SectionsInfoAll.MCC}>
          <If condition={!isEmpty(mccList)}>
            <Column>
              <TitleMCC>MCC</TitleMCC>
              <Title>Descrição</Title>
            </Column>
            {mccList?.map(({ mcc, description }) => {
              return (
                <Card key={mcc}>
                  <DescriptionMCC>{mcc}</DescriptionMCC>
                  <WrapperValue>
                    <Value>
                      {isEmpty(description)
                        ? 'Descrição temporariamente indisponível'
                        : description}
                    </Value>
                  </WrapperValue>
                </Card>
              );
            })}
          </If>
          <If condition={isEmpty(mccList)}>
            <EmptyData title="Sem MCC cadastrados" description="" />
          </If>
        </If>
      </Content>
    </Wrapper>
  );
};

export default ModalListMetaData;
