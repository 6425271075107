import { FC, ReactNode, useEffect, useMemo } from 'react';
import { If, observer } from '~/modules';
import { useStores } from '~/utils';
import {
  Content,
  Heading,
  Item,
  Label,
  Line,
  ProgressBar,
  ProgressItem,
  Rounded,
  Wrapper,
} from './styles';

type StepProps = {
  id: number;
  label: string;
  content: ReactNode;
};

type Props = {
  steps: StepProps[];
};

const ProgressIndicator: FC<Props> = ({ steps }) => {
  const {
    utils: { currentProgressId, setProgressId },
  } = useStores();

  const currentContent = useMemo(() => {
    return steps.find(({ id }) => id === currentProgressId)?.content;
  }, [currentProgressId]);

  const handleProgressIndicator = (id: number) => {
    setProgressId(id);
  };

  useEffect(() => {
    return () => {
      setProgressId(steps[0].id);
    };
  }, []);

  return (
    <Wrapper>
      <Heading>
        <ProgressBar>
          {steps.map(({ id, label }) => (
            <ProgressItem key={id}>
              <Item
                id={`progress_item_${label}`}
                onPress={() => handleProgressIndicator(id)}
              >
                <Rounded active={id <= currentProgressId}>{id}</Rounded>
                <Label>{label}</Label>
              </Item>

              <If condition={id < steps.length}>
                <Line active={id <= currentProgressId - 1} />
              </If>
            </ProgressItem>
          ))}
        </ProgressBar>
      </Heading>
      <Content>{currentContent}</Content>
    </Wrapper>
  );
};

export default observer(ProgressIndicator);
