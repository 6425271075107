import { ReactNode, useMemo } from 'react';
import { faAngleLeft, faAngleRight } from '~/assets';
import { If, LoadingIndicator, isEmpty } from '~/modules';
import { NumberPage, formatTimestampToDateOrToday } from '~/utils';
import {
  AngleIcon,
  BoxPage,
  Column,
  Date,
  EmptyDataComponent,
  Header,
  LoadingBox,
  Title,
  WrapperIcon,
  WrapperPagination,
} from './styles';

type Props<ListItem> = {
  columns?: Array<string>;
  list?: Array<ListItem>;
  totalPages?: number;
  currentPage?: number;
  hasGroupingByPeriod?: boolean;
  isLoading?: boolean;
  showPagination?: boolean;
  isPaginationDisabled?: boolean;
  titleEmptyData: string;
  descriptionEmptyData?: string;
  renderList(list: ListItem, index: number): ReactNode;
  onChangePage?(page: number): void;
  renderPeriodGroup?(list: ListItem): number | string;
};
const PaginatedList = <ListItem,>({
  list = [],
  columns = [],
  currentPage = 0,
  totalPages = 0,
  hasGroupingByPeriod = false,
  isLoading,
  showPagination = true,
  isPaginationDisabled = false,
  titleEmptyData,
  descriptionEmptyData,
  renderList,
  onChangePage,
  renderPeriodGroup,
}: Props<ListItem>) => {
  const onPageChange = (page: number) => onChangePage && onChangePage(page);

  const validationNext = (page: number) => {
    currentPage !== totalPages && onPageChange(page);
  };

  const validationPrevious = (page: number) => {
    currentPage !== NumberPage.ADD && onPageChange(page);
  };

  const periodGroup = (itemList: ListItem) => {
    const period = renderPeriodGroup && renderPeriodGroup(itemList);
    return typeof period === 'number'
      ? formatTimestampToDateOrToday(period)
      : period;
  };

  const makePagination = (pageNumber: number) => {
    return (
      <BoxPage
        currentPage={currentPage === pageNumber}
        onClick={() =>
          !isPaginationDisabled &&
          currentPage !== pageNumber &&
          onPageChange(pageNumber)
        }
      >
        <Title currentPage={currentPage === pageNumber}>{pageNumber}</Title>
      </BoxPage>
    );
  };

  const getPagination = () => {
    const maxPages = 10;
    const isTotalPagesMoreThanMaxPages = totalPages > maxPages;
    const pagesLength = isTotalPagesMoreThanMaxPages ? maxPages : totalPages;
    if (!isTotalPagesMoreThanMaxPages) {
      return Array.from({ length: pagesLength }).map((_, index) =>
        makePagination(index + NumberPage.ADD),
      );
    }
    const pagesList = [];
    const pagesNumberBetween = maxPages - 3;
    pagesList.push(makePagination(NumberPage.ADD));
    Array.from({ length: pagesNumberBetween }).forEach((_, index) => {
      const pageNumber = index + currentPage - 1;
      const isLastIndex = index + 1 === pagesNumberBetween;
      if (index === 0 && pageNumber > NumberPage.SECOND_PAGE) {
        pagesList.push(<Title>...</Title>);
      }
      if (pageNumber < totalPages && pageNumber > NumberPage.ADD) {
        pagesList.push(makePagination(pageNumber));
      }
      if (isLastIndex && pageNumber < totalPages) {
        pagesList.push(<Title>...</Title>);
      }
    });
    pagesList.push(makePagination(totalPages));
    return pagesList;
  };

  const isLeftIconDisabled = useMemo(
    () => currentPage === NumberPage.ADD || isPaginationDisabled,
    [currentPage, isPaginationDisabled],
  );
  const isRightIconDisabled = useMemo(
    () => currentPage === totalPages || isPaginationDisabled,
    [currentPage, totalPages, isPaginationDisabled],
  );

  return (
    <>
      <If condition={!isEmpty(columns) && !isEmpty(list)}>
        <Header columns={columns.length}>
          {columns.map((headerItem) => (
            <Column key={`column-${headerItem}`}>
              <Title>{headerItem}</Title>
            </Column>
          ))}
        </Header>
      </If>
      <If condition={!!isLoading}>
        <LoadingBox>
          <LoadingIndicator />
        </LoadingBox>
      </If>

      <If condition={!isEmpty(list) && !isLoading}>
        <If condition={hasGroupingByPeriod}>
          {list.map((itemList, index) => (
            <>
              <Date>{periodGroup(itemList)}</Date>
              {renderList(itemList, index)}
            </>
          ))}
        </If>

        <If condition={!hasGroupingByPeriod}>
          {list.map((itemList, index) => renderList(itemList, index))}
        </If>

        <If condition={showPagination}>
          <WrapperPagination>
            <WrapperIcon
              onClick={() =>
                !isLeftIconDisabled &&
                validationPrevious(currentPage + NumberPage.SUBTRACTION)
              }
              disabled={isLeftIconDisabled}
            >
              <AngleIcon icon={faAngleLeft} />
            </WrapperIcon>
            {getPagination()}
            <WrapperIcon
              onClick={() =>
                !isRightIconDisabled &&
                validationNext(currentPage + NumberPage.ADD)
              }
              disabled={isRightIconDisabled}
            >
              <AngleIcon icon={faAngleRight} />
            </WrapperIcon>
          </WrapperPagination>
        </If>
      </If>
      <If condition={isEmpty(list) && !isLoading}>
        <EmptyDataComponent
          title={titleEmptyData}
          description={descriptionEmptyData || ''}
        />
      </If>
    </>
  );
};

export default PaginatedList;
