import { getTheme, ifStyle, pxToRem, styled } from '~/modules';
import Modal from '../Modal';
import Typography from '../Typography';

const backgroundZ2 = getTheme('background.z2');
const backgroundZ3 = getTheme('background.z3');
const backgroundZ4 = getTheme('background.z4');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');

const themeRadiusCard = getTheme('themeRadius.card');
const themeRadiusButton = getTheme('themeRadius.button');
const borderRadiusXl = getTheme('borderRadius.xl');

const isActive = ifStyle('active');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(959)};
  max-width: 90%;
  height: ${pxToRem(750)};
  max-height: 95vh;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: ${spacingXl}px;
`;

export const Column = styled.div`
  width: 100%;
  padding: 0 ${spacingLg}px;
  margin-bottom: ${spacingMd}px;
`;

export const Title = styled(Typography)`
  flex: 1;
  font-weight: 500;
`;

export const TitleMCC = styled(Typography)`
  width: 20%;
  font-weight: 500;
`;

export const Card = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: ${pxToRem(96)};
  padding: 0 ${spacingLg}px;
  background-color: ${backgroundZ3};
  border-radius: ${themeRadiusCard}px;
  margin-bottom: ${spacingMd}px;
`;

export const Description = styled(Typography)`
  font-weight: bold;
  flex: 1;
`;

export const DescriptionMCC = styled(Typography)`
  font-weight: bold;
  width: 20%;
`;

export const WrapperValue = styled.div`
  flex: 1;
  align-items: center;
  overflow-x: auto;
  width: ${pxToRem(407)};
  height: ${pxToRem(48)};
`;

export const Value = styled(Typography)``;

export const SectionsBar = styled.div`
  width: 100%;
  height: ${pxToRem(60)};
  padding: ${spacingSm}px;
  background: ${backgroundZ2};
  border-radius: ${borderRadiusXl}px;
`;

type Props = {
  active: boolean;
};
export const SectionItem = styled.div<Props>`
  flex: 1;
  background-color: ${isActive(backgroundZ4, 'none')};
  justify-content: center;
  align-items: center;
  border-radius: ${themeRadiusButton}px;
  font-weight: ${isActive(700)};
  cursor: pointer;
`;

export const WrapperSections = styled.div`
  width: 100%;
  padding: 0 ${spacingXl}px;
  padding-top: ${spacingXl}px;
`;
