import { FC, useState } from 'react';
import { Rounded, Wrapper } from './styles';

type Props = {
  isActive: boolean;
  onPress?(isActive: boolean): void;
};

const ToggleSwitch: FC<Props> = ({ isActive, onPress = () => {} }) => {
  const [isToggleActive, setIsToggleActive] = useState(isActive);

  const handleOnPress = () => {
    setIsToggleActive(!isToggleActive);
    onPress(!isToggleActive);
  };

  return (
    <Wrapper onClick={handleOnPress} isActive={isToggleActive}>
      <Rounded />
    </Wrapper>
  );
};

export default ToggleSwitch;
